import { formatDate } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, SingleDataSet, Color } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { FileUploadComponent } from 'src/app/shared/components/file-upload/file-upload.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { CheckInProjectDaterangeComponent } from '../check-in-project-daterange/check-in-project-daterange.component';
import { CheckinTicketDetailsComponent } from '../checkin-ticket-details/checkin-ticket-details.component';
import { LeaveAbsenceDetailViewComponent } from '../leave-absence-detail-view/leave-absence-detail-view.component';
import { Workbook } from 'exceljs';
import { HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-check-in-attendences',
  templateUrl: './check-in-attendences.component.html',
  styleUrls: ['./check-in-attendences.component.css'],
})
export class CheckInAttendencesComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    // scales: { yAxes: [{}], xAxes: [{}] },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
            // Create scientific notation labels
            // callback: function(value, index, values) {
            //     return value + ' €';
            // }
          },
        },
      ],
      xAxes: [
        {
          // categoryPercentage: 1.0,
          // barPercentage: 0.6
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
    legend: {
      position: 'right',
      labels: {
        fontSize: 13,
        fontFamily: 'Ubuntu',
        padding: 20,
        usePointStyle: true,
      },
    },
  };

  public barChartLabels: Label[] = [''];
  public barChartType: ChartType = 'bar';
  // public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  public barChartPlugins = [];
  locations: any = [
    {
      name: 'All',
      locid: '',
    },
  ];
  typeList: any = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Time In/Out',
      value: 'inout',
    },
    {
      name: 'Check In',
      value: 'checkin',
    },
  ];
  gettingLocList: boolean = false;

  public barChartData: ChartDataSets[] = [
    { data: [0], label: 'Total' },
    { data: [0], label: 'Time In' },
    { data: [0], label: 'Time Out' },
    { data: [0], label: 'Check In' },
    { data: [0], label: 'Activity' },
    { data: [0], label: '' },
  ];

  // public barChartColors = [
  //   {
  //     backgroundColor: ['#11698E', '#089302', 'red'],
  //   },
  // ];
  public barChartColors: Color[] = [
    { backgroundColor: '#11698E' },
    { backgroundColor: 'green' },
    { backgroundColor: '#01ff01' },
    { backgroundColor: 'red' },
    { backgroundColor: 'grey' },
    { backgroundColor: '#ee9b00' },
    { backgroundColor: '#d5bdaf' },
  ];

  public barChartCountColors: Color[] = [
    // { backgroundColor: '#11698E' },
    { backgroundColor: 'green' },
    { backgroundColor: 'red' },
    { backgroundColor: 'grey' },
    { backgroundColor: '#ee9b00' },
    { backgroundColor: '#d5bdaf' },
  ];

  dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
  displayMode = 'Summary';

  tasktype = 'project';
  formatToShow = 'yyyy-MM-dd';
  piedateFormat = 'dd/MM/yyyy';
  dateFormat = 'yyyyMMdd';
  timeFormat = 'hh:mm:ss';
  apitimeFormat = 'hh:mm:ss aa';

  p: number = 1;
  currentPageAS: number = 1;
  currentPageLoc: number = 1;
  q: number = 1;
  itemPerPageDR: number = 100;
  itemPerPageLoc: number = 100;
  itemPerPagePJ: number = 100;
  itemPerPageAS: number = 100;
  mobileViewWidth: any = 426;
  IDText: string = 'User ID';
  IDPlaceholder: string = 'Enter User Name';
  AuthPlaceholder: string = 'User ID';

  memberfocus: boolean = false;

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  // searchLoading: boolean = false;
  downloading: boolean = false;
  searchLoadingSummary: boolean = false;
  searchLoadingDR: boolean = false;
  searchLoadingAR: boolean = false;
  searchLoadingLOC: boolean = false;
  searchLoadingProject: boolean = false;
  searchLoadingMember: boolean = false;

  gettingSummary: boolean = false;
  gettingDaterangeData: boolean = false;
  gettingLocData: boolean = false;
  gettingProjData: boolean = false;
  gettingProjects: boolean = false;
  gettingJobs: boolean = false;
  formSubmitted: boolean = false;
  isFocusDR: boolean = false;
  isFocusAS: boolean = false;
  isFocusPJ: boolean = false;
  isFocusLOC: boolean = false;
  isMobileView: boolean = false;
  isuserID: boolean = true;
  gettingMemberPosition: boolean = false;
  viewAllDRData: boolean = true;
  isAdmin: boolean = false;

  organizations: any[] = [];
  daterangeData: any[] = [];
  absentData: any[] = [];
  daterangeShowData: any[] = [];
  locShowData: any[] = [];
  projData: any[] = [];
  projects: any[] = [
    {
      name: '-',
      projectid: '',
    },
  ];
  jobs: any[] = [
    {
      name: '-',
      jobid: '',
    },
  ];
  dateArray = [''];
  sumType: any[] = ['Employee', 'Counts'];

  statusInterval: any;

  domainid: string = this.allinoneService.getDomain().domainid;
  importData: any;
  importStatus: string = '000'; //000- Server Error, 001-Success, 002-Progress, 003-Upload, 004-Error, 005-Cancel
  refreshingImportStatus: boolean = false;

  subscriptions = new SubSink();
  // key: any = '';
  // start: number = 0;
  // end: number = Number(this.itemPerPageLoc);
  // filename: string = '';
  locTotal: number = 0;
  // locfileurl: string = '';
  // locsearchfilename: string = '';
  // locsearchfileurl: string = '';
  // isSearch: boolean = false;

  summaryData = {
    total: '',
    timein: '',
    timeout: '',
    usertimein: '',
    useractive: '',
    usertimeout: '',
    checkin: '',
    usercheckin: '',
    activity: '',
    useractivity: '',
    other: '',
    userother: '',
    notimein: '',
    noactivity: '',
    leave: '',
  };

  daterangePG = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  locPG = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];
  absentPG = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  projectPG = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  branchList: string[] = ['All'];
  tempBranchList: string[] = [];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];

  summaryStartDate: any = '';

  selectedOrg: any = '';
  selectedOrg1: any = '';
  selectedOrg2: any = '';
  selectedOrg3: any = '';
  searchTextPJ: any = '';
  searchTextDR: any = '';
  searchTextAS: any = '';
  searchTextLOC: any = '';
  projStartDate: any = '';
  projEndDate: any = '';

  nextDisabled: boolean = false;
  lastPage: any = '';
  primaryKey: any = '';
  currentPage: number = 1;
  isrefresh: boolean = false;
  itemPerPage: any = 100;

  // d = new Date();
  // month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  // day = this.d.getDate().toString().padStart(2, '0');
  // todayDate = '' + this.d.getFullYear() + this.month + this.day;
  //for filter date
  // aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  // aweekBeforeDate = new Date(this.aweekBefore);
  // checkInStartDate =
  //   this.aweekBeforeDate.getFullYear() +
  //   (this.aweekBeforeDate.getMonth() + 1).toString().padStart(2, '0') +
  //   this.aweekBeforeDate.getDate().toString().padStart(2, '0') +
  //   '';
  // checkInEndDate = this.d.getFullYear() + this.month + this.day + '';
  //show today date
  // showTodayDate =
  //   this.day +
  //   '/' +
  //   this.month +
  //   '/' +
  //   this.d.getFullYear() +
  //   ' ' +
  //   this.dayNames[this.d.getDay()];
  // dateRangeStartDate: any = '';
  // minDate = '';

  // currentEndDate = formatDate(
  //   Date.now(),
  //   this.formatToShow,
  //   'en-US'
  // ).toString();
  currentEndDate = new Date(Date.now());

  // currentStartDate = formatDate(
  //   new Date(this.aweekBefore),
  //   this.formatToShow,
  //   'en-US'
  // ).toString();
  tdyDate = formatDate(new Date(), this.formatToShow, 'en-US').toString();

  atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
  atFormStartTime = formatDate(Date.now(), this.timeFormat, 'en-US').toString();
  // atFormEndTime = formatDate(
  //   new Date(this.d).setHours(new Date(this.d).getHours() + 1),
  //   this.timeFormat,
  //   'en-US'
  // ).toString();

  // websockect
  // connectSocketLoading: boolean = true;
  connectSocketLoadingDR: boolean = true;
  connectSocketLoadingAR: boolean = true;
  connectSocketLoadingLOC: boolean = true;
  connectSocketLoadingSummary: boolean = true;
  connectSocketLoadingMember: boolean = true;

  // private socket: WebSocket | undefined;
  private socketDR: WebSocket | undefined;
  private socketAR: WebSocket | undefined;
  private socketLOC: WebSocket | undefined;
  private socketSummary: WebSocket | undefined;
  private socketMember: WebSocket | undefined;

  // public socketConnectionStatus: string = 'disconnected';
  public socketConnectionStatusDR: string = 'disconnected';
  public socketConnectionStatusAR: string = 'disconnected';
  public socketConnectionStatusLOC: string = 'disconnected';
  public socketConnectionStatusSummary: string = 'disconnected';
  public socketConnectionStatusMember: string = 'disconnected';

  // public socketReceivedMessage: string = '';
  public socketReceivedMessageDR: string = '';
  public socketReceivedMessageAR: string = '';
  public socketReceivedMessageLOC: string = '';
  public socketReceivedMessageSummary: string = '';
  public socketReceivedMessageMember: string = '';

  // connectionId: string = '';
  connectionIdDR: string = '';
  connectionIdAR: string = '';
  connectionIdLOC: string = '';
  connectionIdSummary: string = '';
  connectionIdMember: string = '';

  // drsocketfileurl: any = '';
  drsocketfileurlDR: any = '';
  drsocketfileurlAR: any = '';
  drsocketfileurlLOC: any = '';
  drsocketfileurlMember: any = '';

  // updateCId: boolean = false;
  updateCIdDR: boolean = false;
  updateCIdAR: boolean = false;
  updateCIdLOC: boolean = false;
  updateCIdSummary: boolean = false;
  updateCIdMember: boolean = false;

  // socketConnectTime: any;
  socketConnectTimeDR: any;
  socketConnectTimeAR: any;
  socketConnectTimeLOC: any;
  socketConnectTimeSummary: any;
  socketConnectTimeMember: any;

  // Date
  d = new Date();
  dateRangeStartDate: any = '';
  // minDate = '';
  minDate = new Date();
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  // currentStartDate = formatDate(
  //   new Date(this.aweekBefore),
  //   this.formatToShow,
  //   'en-US'
  // ).toString();

  currentStartDate = new Date(this.aweekBefore);

  userStatusFilterList = this.allinoneService.userStatusFilterList;

  getcheckinForm = {
    startdate:
      this.displayMode == 'Summary'
        ? this.currentEndDate
        : this.currentStartDate,
    enddate: this.currentEndDate,
    domain: this.allinoneService.getDomain().shortcode,
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    orgname: '',
    projindex: '',
    projectname: '-',
    projectid: '',
    jobindex: '',
    jobname: '-',
    jobid: '',
    subtype: 'Employee',
    userid: '',
    department: 'All',
    division: 'All',
    branch: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
    status: '001',
    drdepartment: 'All',
    drdivision: 'All',
    drteamid: 'All',
    drcostcenter: 'All',
    drsubdivision: 'All',
    drstatus: '001',
    locname: 'All',
    locid: '',
    type: '',
    typename: 'All',
    membertype: 'User Name',
  };

  dataList: any;
  atFormSubmitted = false;
  sortedArray: any = [];
  inbranchList: any = [];
  outbranchList: any = [];
  checkinbranchList: any = [];
  combinedbranchList: any = [];

  summarySubscription!: Subscription;

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private filter: FilterPipe,
    private http: HttpClient
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'hradmin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl(
            this.allinoneService.isAttendanceView ? '/attendance/hxm' : '/hxm'
          );
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.cleanUp();
  }

  cleanUp() {
    this.subscriptions.unsubscribe();
    this.summarySubscription && this.summarySubscription.unsubscribe();
    this.socketDR?.close(3001);
    this.socketAR?.close(3001);
    this.socketLOC?.close(3001);
    this.socketSummary?.close(3001);
    this.socketMember?.close(3001);
    clearInterval(this.statusInterval);
    // clearTimeout(this.socketConnectTime);
    clearTimeout(this.socketConnectTimeDR);
    clearTimeout(this.socketConnectTimeAR);
    clearTimeout(this.socketConnectTimeLOC);
    clearTimeout(this.socketConnectTimeSummary);
    clearTimeout(this.socketConnectTimeMember);
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    this.organizations = this.allinoneService.orgs;
    this.getcheckinForm.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;

    this.selectedOrg = this.getcheckinForm.orgid;
    this.selectedOrg1 = this.getcheckinForm.orgid;
    this.selectedOrg2 = this.getcheckinForm.orgid;
    this.getcheckinForm.orgname = this.organizations.filter(
      (x: any) => x.orgid == this.getcheckinForm.orgid
    )[0].name;

    this.summaryStartDate = this.getcheckinForm.startdate;
    this.dateRangeStartDate = this.currentStartDate;

    console.log(this.getcheckinForm);
    var currentEndDate = formatDate(
      this.getcheckinForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    var addDays = 35;
    // var addDays = 31;
    var date = new Date(this.dateRangeStartDate);
    console.log(date);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    console.log(date);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;

    // var date = new Date(this.getcheckinForm.startdate);

    this.barChartOptions.title = {
      display: true,
      position: 'top',
      fontFamily: 'Ubuntu',
      fontSize: 15,
      text: this.getcheckinForm.orgname + ' - ' + currentEndDate,
      fullWidth: false,
    };
    this.getImportStatus();
    this.statusInterval = setInterval(() => {
      if (
        this.importStatus != '001' &&
        this.importStatus != '005' &&
        this.importStatus != '000'
      ) {
        this.getImportStatus();
      }
    }, 5000);
    this.getMemberPosition();
    // this.getSummary();
    this.connectWebSocketSummary();
    this.closeSocketWithTimeSummary();
    this.getProjects();
    this.getLocation();
  }

  connectWebSocketDateRange() {
    console.log('Calling Websocket');
    this.searchLoadingDR = true;
    this.connectSocketLoadingDR = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socketDR = new WebSocket(webSocketURL);

    this.socketDR.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoadingDR = false;
      // this.socketDR?.send('001');
      this.socketConnectionStatusDR = 'connected';
    });

    this.socketDR.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessageDR = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionIdDR = tempData.connectionId;

        if (this.updateCIdDR) {
          this.updateConnectionID();
        }
        this.searchWithDateRangeWithSocket(false);
      } else {
        this.drsocketfileurlDR = tempData.fileurl;

        if (this.drsocketfileurlDR) {
          this.http.get(this.drsocketfileurlDR).subscribe((res: any) => {
            console.log('Your get data res');
            console.log(res);
            this.daterangeData = res;
            this.daterangeShowData = this.daterangeData;
            this.daterangePG[3].count = res.length;

            this.searchLoadingDR = false;
            this.connectionIdDR = '';
            this.socketDR?.close(3001);
            clearTimeout(this.socketConnectTimeDR);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionIdDR);
    });

    this.socketDR.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatusDR = 'error';
    });

    this.socketDR.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatusDR = 'disconnected';
      if (event.code != 3001) {
        this.connectionIdDR = '';
        this.updateCIdDR = true;
        this.connectWebSocketDateRange();
      }
    });
  }

  connectWebSocketAbsentReport() {
    console.log('Calling Websocket');
    this.searchLoadingAR = true;
    this.connectSocketLoadingAR = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socketAR = new WebSocket(webSocketURL);

    this.socketAR.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoadingAR = false;
      // this.socketAR?.send('001');
      this.socketConnectionStatusAR = 'connected';
    });

    this.socketAR.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessageAR = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionIdAR = tempData.connectionId;

        if (this.updateCIdAR) {
          this.updateConnectionID();
        }
        this.searchWithDateRangeWithSocket(true);
      } else {
        this.drsocketfileurlAR = tempData.fileurl;

        if (this.drsocketfileurlAR) {
          this.http.get(this.drsocketfileurlAR).subscribe((res: any) => {
            console.log('Your get data res');
            console.log(res);
            this.absentData = res;
            this.daterangePG[3].count = res.length;

            this.searchLoadingAR = false;
            this.connectionIdAR = '';
            this.socketAR?.close(3001);
            clearTimeout(this.socketConnectTimeAR);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionIdAR);
    });

    this.socketAR.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatusAR = 'error';
    });

    this.socketAR.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatusAR = 'disconnected';
      if (event.code != 3001) {
        this.connectionIdAR = '';
        this.updateCIdAR = true;
        this.connectWebSocketAbsentReport();
      }
    });
  }

  connectWebSocketLocation() {
    console.log('Calling Websocket');
    this.searchLoadingLOC = true;
    this.connectSocketLoadingLOC = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socketLOC = new WebSocket(webSocketURL);

    this.socketLOC.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoadingLOC = false;
      // this.socketLOC?.send('001');
      this.socketConnectionStatusLOC = 'connected';
    });

    this.socketLOC.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessageLOC = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionIdLOC = tempData.connectionId;

        if (this.updateCIdLOC) {
          this.updateConnectionID();
        }
        this.searchWithLoc();
      } else {
        this.drsocketfileurlLOC = tempData.fileurl;

        if (this.drsocketfileurlLOC) {
          this.http.get(this.drsocketfileurlLOC).subscribe((res: any) => {
            console.log('Your get data res');
            console.log(res);
            this.locShowData = res;
            this.locTotal = res.length;
            this.gettingLocData = false;

            this.searchLoadingLOC = false;
            this.connectionIdLOC = '';
            this.socketLOC?.close(3001);
            clearTimeout(this.socketConnectTimeLOC);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionIdLOC);
    });

    this.socketLOC.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatusLOC = 'error';
    });

    this.socketLOC.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatusLOC = 'disconnected';
      if (event.code != 3001) {
        this.connectionIdLOC = '';
        this.updateCIdLOC = true;
        this.connectWebSocketLocation();
      }
    });
  }

  connectWebSocketMember() {
    console.log('Calling Websocket');
    this.searchLoadingMember = true;
    this.connectSocketLoadingMember = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socketMember = new WebSocket(webSocketURL);

    this.socketMember.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoadingMember = false;
      // this.socketLOC?.send('001');
      this.socketConnectTimeMember = 'connected';
    });

    this.socketMember.addEventListener('message', (event) => {
      console.log('Received message:', event.data);
      this.socketReceivedMessageMember = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionIdMember = tempData.connectionId;

        if (this.updateCIdMember) {
          this.updateConnectionID();
        }
        this.getCheckInList();
      } else {
        this.drsocketfileurlMember = tempData.fileurl;

        if (this.drsocketfileurlMember) {
          this.sortedArray = [];
          this.http.get(this.drsocketfileurlMember).subscribe((res: any) => {
            this.dataList = res.reverse();
            if (this.dataList.length > 0) {
              var userList: any = [];
              for (var user = 0; user < this.dataList.length; user++) {
                var checkUserData: any = userList.filter(
                  (a: any) => a.userid == this.dataList[user].userid
                );

                if (checkUserData.length == 0) {
                  userList.push({
                    userid: this.dataList[user].userid,
                    username: this.dataList[user].username,
                  });
                }
              }
              userList = userList.sort();
              for (var x = 0; x < userList.length; x++) {
                var namedCheckInarray = [];
                this.dateArray = [];

                var userCheckInDataList = this.dataList.filter(
                  (a: any) => a.userid == userList[x].userid
                );

                for (var i = 0; i < userCheckInDataList.length; i++) {
                  var resc: any = this.dateArray.filter(
                    (x) => x == userCheckInDataList[i].date
                  );
                  console.log(resc.length);

                  if (resc.length == 0) {
                    this.dateArray.push(userCheckInDataList[i].date);
                    var ddate = userCheckInDataList[i].date;
                    var y = ddate.slice(0, 4);
                    var m = ddate.slice(4, 6);
                    var day = ddate.slice(6, 9);
                    var sd = new Date(y + '-' + m + '-' + day);
                    var cdata: any = userCheckInDataList.filter(
                      (a: any) => a.date == userCheckInDataList[i].date
                    );
                    var obj = {
                      date: userCheckInDataList[i].date,
                      showdate:
                        day +
                        '/' +
                        m +
                        '/' +
                        y +
                        ' ' +
                        this.dayNames[sd.getDay()],
                      data: cdata,
                      show: true,
                      isoffday: sd.getDay() == 0 || sd.getDay() == 6,
                    };
                    namedCheckInarray.push(obj);
                    namedCheckInarray.sort(
                      // (a:any,b:any)=>parseInt(a.date)<parseInt(b.date)
                      (a: any, b: any) => {
                        {
                          let value = parseInt(b.date) - parseInt(a.date);
                          return value;
                        }
                      }
                    );
                  }
                }

                this.sortedArray.push({
                  userid: userList[x].userid,
                  username: userList[x].username,
                  datalist: namedCheckInarray,
                  show: false,
                });

                if (this.sortedArray.length == 1) {
                  this.sortedArray[0].show = true;
                }
              }
            }

            this.searchLoadingMember = false;
            this.connectionIdMember = '';
            this.socketMember?.close(3001);
            clearTimeout(this.socketConnectTimeMember);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionIdLOC);
    });

    this.socketMember.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatusMember = 'error';
    });

    this.socketMember.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatusMember = 'disconnected';
      if (event.code != 3001) {
        this.connectionIdMember = '';
        this.updateCIdMember = true;
        this.connectWebSocketMember();
      }
    });
  }

  callSummary() {
    this.socketSummary?.close(3001);
    this.connectSocketLoadingSummary = true;
    this.socketConnectionStatusSummary = 'disconnected';
    this.connectionIdSummary = '';
    this.socketReceivedMessageSummary = '';
    this.updateCIdSummary = false;
    clearTimeout(this.socketConnectTimeSummary);
    this.connectWebSocketSummary();
    this.closeSocketWithTimeSummary();
  }

  connectWebSocketSummary() {
    console.log('Calling Websocket For Summary');
    this.searchLoadingSummary = true;
    this.gettingSummary = true;
    this.connectSocketLoadingSummary = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socketSummary = new WebSocket(webSocketURL);

    this.socketSummary.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoadingSummary = false;
      // this.socketSummary?.send('001');
      this.socketConnectionStatusSummary = 'connected';
    });

    this.socketSummary.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessageSummary = event.data;
      // console.log(this.socketReceivedMessageSummary);

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionIdSummary = tempData.connectionId;

        if (this.updateCIdSummary) {
          this.updateConnectionID();
        }
        this.getSummary();
      } else {
        if (tempData.returncode == 300) {
          // this.inbranchList = tempData.inbranchlist;
          // this.outbranchList = tempData.outbranchlist;
          // this.checkinbranchList = tempData.checkbranchlist;
          this.summaryData = {
            total: tempData.orgusercounts,
            timein: tempData.timeincounts,
            timeout: tempData.timeoutcounts,
            usertimein: tempData.timeinusercounts,
            useractive: tempData.activeusercounts,
            usertimeout: tempData.timeoutusercounts,
            checkin: tempData.checkincounts,
            usercheckin: tempData.checkinusercounts,
            activity: tempData.activitycounts,
            useractivity: tempData.activityusercounts,
            other: tempData.othercounts,
            userother: tempData.otherusercounts,
            notimein: tempData.notimeinusercounts,
            noactivity: tempData.noactivityusercounts,
            leave: tempData.leavecounts,
          };

          // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
          if (this.getcheckinForm.subtype == 'Employee') {
            this.barChartData = [
              { data: [parseInt(this.summaryData.total)], label: 'Total' },
              {
                data: [parseInt(this.summaryData.usertimein)],
                label: 'Time In',
              },
              {
                data: [parseInt(this.summaryData.useractive)],
                label: 'Active',
              },
              {
                data: [parseInt(this.summaryData.usertimeout)],
                label: 'Time Out',
              },
              {
                data: [parseInt(this.summaryData.usercheckin)],
                label: 'Check In',
              },
              {
                data: [parseInt(this.summaryData.useractivity)],
                label: 'Activity',
              },
              {
                data: [parseInt(this.summaryData.leave)],
                label: 'Leave',
              },
              // {
              //   data: [parseInt(this.summaryData.notimein)],
              //   label: 'No Time In',
              // },
              // {
              //   data: [parseInt(this.summaryData.noactivity)],
              //   label: 'No Activity',
              // },
            ];
          } else {
            this.barChartData = [
              // { data: [parseInt(this.summaryData.total)], label: 'Total' },
              { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
              {
                data: [parseInt(this.summaryData.timeout)],
                label: 'Time Out',
              },
              {
                data: [parseInt(this.summaryData.checkin)],
                label: 'Check In',
              },
              {
                data: [parseInt(this.summaryData.activity)],
                label: 'Activity',
              },
            ];
          }
          this.gettingSummary = false;
          this.socketSummary?.close(3001);
          clearTimeout(this.socketConnectTimeSummary);
        }
      }

      console.log('c id');
      console.log(this.connectionIdSummary);
    });

    this.socketSummary.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.gettingSummary = false;
      this.socketConnectionStatusSummary = 'error';
    });

    this.socketSummary.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatusSummary = 'disconnected';
      if (event.code != 3001) {
        this.connectionIdSummary = '';
        this.gettingSummary = false;
        this.updateCIdSummary = true;
        this.connectWebSocketSummary();
      }
    });
  }

  updateConnectionID() {
    var t = '001';
    if (this.displayMode == 'Daterange') {
      t = '001';
    } else if (this.displayMode == 'Absentreport') {
      t = '001';
    } else if (this.displayMode == 'Location') {
      t = '006';
    } else if (this.displayMode == 'Summary') {
      t = '012';
    }
    var data = {};
    if (this.displayMode == 'Daterange') {
      data = {
        connectionid: this.connectionIdDR,
        type: t,
      };
    } else if (this.displayMode == 'Absentreport') {
      data = {
        connectionid: this.connectionIdAR,
        type: t,
      };
    } else if (this.displayMode == 'Location') {
      data = {
        connectionid: this.connectionIdLOC,
        type: t,
      };
    } else if (this.displayMode == 'Summary') {
      data = {
        connectionid: this.connectionIdSummary,
        type: t,
      };
    }
    this.kunyekService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      // this.updateCId = false;
      if (this.displayMode == 'Daterange') {
        this.updateCIdDR = false;
      } else if (this.displayMode == 'Absentreport') {
        this.updateCIdAR = false;
      } else if (this.displayMode == 'Location') {
        this.updateCIdLOC = false;
      } else if (this.displayMode == 'Summary') {
        this.updateCIdSummary = false;
      }
      console.log(res);
    });
  }

  summaryDepartmentChange(dep: any) {
    if (this.getcheckinForm.department != dep) {
      this.getcheckinForm.department = dep;
      this.getcheckinForm.division = 'All';
      this.getcheckinForm.teamid = 'All';
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.subdivision = 'All';
      // this.getSummary();
      this.callSummary();
    }
  }

  summaryBranchChange(bra: any) {
    if (this.getcheckinForm.branch != bra) {
      this.getcheckinForm.branch = bra;
      this.getcheckinForm.division = 'All';
      this.getcheckinForm.teamid = 'All';
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.subdivision = 'All';
      // this.getSummary();
      this.callSummary();
    }
  }

  summaryDivisionChange(divi: any) {
    if (this.getcheckinForm.division != divi) {
      this.getcheckinForm.division = divi;
      this.getcheckinForm.teamid = 'All';
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.subdivision = 'All';
      // this.getSummary();
      this.callSummary();
    }
  }

  summaryTeamidChange(teamid: any) {
    if (this.getcheckinForm.teamid != teamid) {
      this.getcheckinForm.teamid = teamid;
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.subdivision = 'All';
      // this.getSummary();
      this.callSummary();
    }
  }

  summaryCostCenterChange(costcenter: any) {
    if (this.getcheckinForm.costcenter != costcenter) {
      this.getcheckinForm.costcenter = costcenter;
      this.getcheckinForm.subdivision = 'All';
      // this.getSummary();
      this.callSummary();
    }
  }

  summarySubDivisionChange(subdivision: any) {
    if (this.getcheckinForm.subdivision != subdivision) {
      this.getcheckinForm.subdivision = subdivision;
      // this.getSummary();
      this.callSummary();
    }
  }

  summaryUserTypeChange(type: any) {
    if (this.getcheckinForm.status != type) {
      this.getcheckinForm.status = type;
      this.callSummary();
    }
  }

  daterangeDepartmentChange(dep: any) {
    if (this.getcheckinForm.drdepartment != dep) {
      this.getcheckinForm.drdepartment = dep;
      this.getcheckinForm.drdivision = 'All';
      this.getcheckinForm.drteamid = 'All';
      this.getcheckinForm.drcostcenter = 'All';
      this.getcheckinForm.drsubdivision = 'All';
    }
  }

  daterangeDivisionChange(divi: any) {
    if (this.getcheckinForm.drdivision != divi) {
      this.getcheckinForm.drdivision = divi;
      this.getcheckinForm.drteamid = 'All';
      this.getcheckinForm.drcostcenter = 'All';
      this.getcheckinForm.drsubdivision = 'All';
    }
  }

  daterangeTeamidChange(teamid: any) {
    if (this.getcheckinForm.drteamid != teamid) {
      this.getcheckinForm.drteamid = teamid;
      this.getcheckinForm.drcostcenter = 'All';
      this.getcheckinForm.drsubdivision = 'All';
    }
  }

  datecostCenterChange(costcenter: any) {
    if (this.getcheckinForm.drcostcenter != costcenter) {
      this.getcheckinForm.drcostcenter = costcenter;
      this.getcheckinForm.drsubdivision = 'All';
    }
  }

  datesubDivisionChange(subdivision: any) {
    if (this.getcheckinForm.drsubdivision != subdivision) {
      this.getcheckinForm.drsubdivision = subdivision;
    }
  }

  departmentChange(dep: any) {
    if (this.getcheckinForm.department != dep) {
      this.getcheckinForm.department = dep;
      this.getcheckinForm.division = 'All';
      this.getcheckinForm.teamid = 'All';
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.division = 'All';
    }
  }

  divisionChange(divi: any) {
    if (this.getcheckinForm.division != divi) {
      this.getcheckinForm.division = divi;
      this.getcheckinForm.teamid = 'All';
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.division = 'All';
    }
  }

  teamidChange(teamid: any) {
    if (this.getcheckinForm.teamid != teamid) {
      this.getcheckinForm.teamid = teamid;
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.division = 'All';
    }
  }

  costCenterChange(costcenter: any) {
    if (this.getcheckinForm.costcenter != costcenter) {
      this.getcheckinForm.costcenter = costcenter;
      this.getcheckinForm.division = 'All';
    }
  }

  subDivisionChange(subdivision: any) {
    if (this.getcheckinForm.subdivision != subdivision) {
      this.getcheckinForm.subdivision = subdivision;
    }
  }

  locationChange(loc: any) {
    if (this.getcheckinForm.locid != loc.locid) {
      this.getcheckinForm.locname = loc.name;
      this.getcheckinForm.locid = loc.locid;
    }
  }

  typeChange(type: any) {
    if (this.getcheckinForm.type != type.value) {
      this.getcheckinForm.type = type.value;
      this.getcheckinForm.typename = type.name;
    }
  }

  projectChange(proj: any, index: any) {
    this.getcheckinForm.projindex = index;
    if (this.getcheckinForm.projectid != proj.projectid) {
      this.getcheckinForm.projectid = proj.projectid;
      this.getcheckinForm.projectname = proj.name;
      if (proj.projectid != '') {
        this.getJobs();
      }
    }
  }

  jobChange(proj: any, index: any) {
    this.getcheckinForm.jobindex = index;
    if (this.getcheckinForm.jobid != proj.jobid) {
      this.getcheckinForm.jobid = proj.jobid;
      this.getcheckinForm.jobname = proj.name;
    }
  }

  startdateChange() {
    var currentEndDate = formatDate(
      this.getcheckinForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    var addDays = 35;
    var date = new Date(this.getcheckinForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;
    if (
      this.getcheckinForm.enddate > this.minDate ||
      this.getcheckinForm.enddate < this.getcheckinForm.startdate
    ) {
      this.getcheckinForm.enddate = this.getcheckinForm.startdate;
    }
  }

  // pageChange(event: any) {
  //   // console.log(event);
  //   if (this.nextDisabled == true) {
  //     if (event < this.lastPage) {
  //       this.currentPageLoc = event;
  //       if (event < 1) {
  //       } else {
  //         this.nextDisabled = false;
  //       }
  //     }
  //   } else {
  //     console.log('else con');

  //     if (event == this.lastPage && this.lastPage != '') {
  //       this.currentPageLoc = event;
  //       this.nextDisabled = true;
  //     } else {
  //       this.currentPageLoc = event;
  //       if (event > this.locShowData.length / this.itemPerPageLoc) {
  //         this.searchLocWithPagination();
  //       }
  //       else {
  //         this.nextDisabled = false;
  //       }
  //     }
  //   }
  // }

  // resetPagination() {
  //   this.currentPageLoc = 1;
  //   this.lastPage = '';
  //   this.nextDisabled = false;
  // }

  getLocation() {
    this.locations = [
      {
        name: 'All',
        locid: '',
      },
    ];
    this.gettingLocList = true;
    // data = {
    //   "global": "",
    //   "domainid": this.eventForm.domain,
    //   "orgid": [this.eventForm.org],
    //   "userid": ""
    // }
    // if (this.role == "300" || this.eventForm.org != "") {
    var data = {
      global: '',
      domainid: '',
      orgid: [this.getcheckinForm.orgid],
      personal: '',
    };
    // }
    this.kunyekService.getLocations(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        console.log('loc res');

        console.log(res);

        res.datalist.map((loc: any) => {
          this.locations.push({
            locid: loc.locid,
            lat: loc.lat,
            lng: loc.lng,
            name: loc.name,
            range: loc.range,
            type: loc.type,
            typeid: loc.typeid,
            description: loc.description,
          });
        });
        this.gettingLocList = false;
      } else {
        this.gettingLocList = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    });
  }

  locChanged(event: any) {
    this.getcheckinForm.locid = event.target.value;
  }

  changeDrPageCount(event: any) {
    this.itemPerPageDR = event.target.value;
    this.p = 1;
  }

  changeLocPageCount(event: any) {
    this.itemPerPageLoc = event.target.value;
    this.p = 1;
    this.currentPageLoc = 1;
    // this.locTotal = 0;
    // this.start = 0;
    // this.end = Number(this.itemPerPageLoc);
    // this.searchWithLoc();
  }

  changeASPageCount(event: any) {
    this.itemPerPageAS = event.target.value;
    this.currentPageAS = 1;
  }

  changePjPageCount(event: any) {
    this.itemPerPagePJ = event.target.value;
    this.q = 1;
  }

  viewDetails(type: string) {
    console.log('this fun');

    var date = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.dateFormat,
      'en-US'
    ).toString();
    const branch =
      this.getcheckinForm.branch == 'All'
        ? 'all'
        : this.getcheckinForm.branch;
    const department =
      this.getcheckinForm.department == 'All'
        ? 'all'
        : this.getcheckinForm.department;
    const division =
      this.getcheckinForm.division == 'All'
        ? 'all'
        : this.getcheckinForm.division;
    const teamid =
      this.getcheckinForm.teamid == 'All' ? 'all' : this.getcheckinForm.teamid;
    const costcenter =
      this.getcheckinForm.costcenter == 'All'
        ? 'all'
        : this.getcheckinForm.costcenter;
    const subdivision =
      this.getcheckinForm.subdivision == 'All'
        ? 'all'
        : this.getcheckinForm.subdivision;

    console.log('here >>>');
    console.log(costcenter);
    console.log(subdivision);

    if (this.getcheckinForm.subtype == 'Employee') {
      console.log('1');

      this.router.navigate([
        (this.isAdmin
          ? this.allinoneService.isAttendanceView
            ? '/attendance/hxm'
            : '/hxm'
          : '') +
          '/checkinattendances/summary/' +
          this.getcheckinForm.orgid +
          '/user' +
          type +
          '/' +
          branch +
          '/' +
          department +
          '/' +
          division +
          '/' +
          teamid +
          '/' +
          costcenter +
          '/' +
          subdivision +
          '/' +
          date,
      ]);
    } else {
      console.log('2');
      this.router.navigate([
        (this.isAdmin
          ? this.allinoneService.isAttendanceView
            ? '/attendance/hxm'
            : '/hxm'
          : '') +
          '/checkinattendances/summary/' +
          this.getcheckinForm.orgid +
          '/' +
          type +
          '/' +
          branch +
          '/' +
          department +
          '/' +
          division +
          '/' +
          teamid +
          '/' +
          costcenter +
          '/' +
          subdivision +
          '/' +
          date,
      ]);
    }
  }

  viewDateRangeDetails(
    type: string,
    userid: string,
    username: string,
    days: string,
    hours: string,
    iohours: string
  ) {
    var startdate = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.dateFormat,
      'en-US'
    ).toString();
    var enddate = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.dateFormat,
      'en-US'
    ).toString();
    var tempUser = {
      userid: userid,
      username: username,
      hours: hours,
      days: days,
      iohours: iohours,
    };
    var user = this.allinoneService.replaceAll(
      this.allinoneService.encrypt(JSON.stringify(tempUser)),
      '/',
      '###'
    );

    this.router.navigate([
      (this.isAdmin
        ? this.allinoneService.isAttendanceView
          ? '/attendance/hxm'
          : '/hxm'
        : '') +
        '/checkinattendances/daterange/' +
        this.getcheckinForm.orgid +
        '/' +
        type +
        '/' +
        startdate +
        enddate +
        '/' +
        user,
    ]);
  }

  viewTaskDetails(item: any) {
    var type = this.tasktype;
    var tempUser = {
      userid: item.userid,
      username: item.username,
      hours: item.hours,
      projectname: this.projects[parseInt(this.getcheckinForm.projindex)].name,
    };
    var user = this.allinoneService.replaceAll(
      this.allinoneService.encrypt(JSON.stringify(tempUser)),
      '/',
      '###'
    );
    sessionStorage.setItem('projdetails', JSON.stringify(item.datalist));
    this.router.navigate([
      (this.isAdmin
        ? this.allinoneService.isAttendanceView
          ? '/attendance/hxm'
          : '/hxm'
        : '') +
        '/checkinattendances/task/' +
        this.getcheckinForm.orgid +
        '/' +
        type +
        '/' +
        user,
    ]);
  }

  getProjects() {
    return new Promise((resolve: any) => {
      this.gettingProjects = true;
      this.projects = [
        {
          name: '-',
          projectid: '',
        },
      ];
      this.jobs = [
        {
          name: '-',
          jobid: '',
        },
      ];
      const data = {
        orgid: this.getcheckinForm.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      };
      console.log(`Data: ${JSON.stringify(data)}`);
      this.subscriptions.sink = this.kunyekService
        .getAllProjects(data)
        .subscribe(
          (res: any) => {
            console.log(this.getcheckinForm.projindex,'...........proj ind')
            if (res.returncode == '300') {
              // now
              // this.projects = res.data;
              for (var i = 0; i < res.data.length; i++) {
                this.projects.push(res.data[i]);
              }

              this.getcheckinForm.projindex =
                this.projects.length > 0 ? '0' : '';
              if (
                this.getcheckinForm.projindex != '' &&
                this.getcheckinForm.projindex != '0'
              ) {
                this.getJobs();
              }
              resolve(true);
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
              resolve(false);
            }
            this.gettingProjects = false;
          },
          (err) => {
            resolve(false);
            this.gettingProjects = false;
          }
        );
    });
  }

  getJobs() {
    this.gettingJobs = true;
    this.jobs = [
      {
        name: '-',
        jobid: '',
      },
    ];
    this.getcheckinForm.jobindex = '';
    this.getcheckinForm.jobname = '-';
    this.getcheckinForm.jobid = '';
    const data = {
      projectid:
        this.projects[parseInt(this.getcheckinForm.projindex)].projectid,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
    };
    this.kunyekService.getAllJobs(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.jobs = res.data;
          for (var i = 0; i < res.data.length; i++) {
            this.jobs.push(res.data[i]);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingJobs = false;
      },
      (err) => {
        this.gettingJobs = false;
      }
    );
  }

  projChanged(event: any) {
    this.getJobs();
  }

  jobChanged(event: any) {}

  // closeSocketWithTime() {
  //   this.socketConnectTime = setTimeout(() => {
  //     if (this.socketConnectionStatus != 'disconnected') {
  //       console.log('Time out');

  //       this.socketDR?.close(3001);
  //       this.socketAR?.close(3001);
  //       this.socketLOC?.close(3001);
  //       this.socketSummary?.close(3001);
  //       this.connectionId = '';
  //       this.messageService.openSnackBar('Request Time Out', 'warn');
  //       this.searchLoading = false;
  //     }
  //   }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  // }

  closeSocketWithTimeDR() {
    this.socketConnectTimeDR = setTimeout(() => {
      if (this.socketConnectionStatusDR != 'disconnected') {
        console.log('Time out');

        this.socketDR?.close(3001);
        this.connectionIdDR = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoadingDR = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  closeSocketWithTimeAR() {
    this.socketConnectTimeAR = setTimeout(() => {
      if (this.socketConnectionStatusAR != 'disconnected') {
        console.log('Time out');

        this.socketAR?.close(3001);
        this.connectionIdAR = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoadingAR = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  closeSocketWithTimeLOC() {
    this.socketConnectTimeLOC = setTimeout(() => {
      if (this.socketConnectionStatusLOC != 'disconnected') {
        console.log('Time out');
        this.socketLOC?.close(3001);
        this.connectionIdLOC = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoadingLOC = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  closeSocketWithTimeMember() {
    this.socketConnectTimeMember = setTimeout(() => {
      if (this.socketConnectionStatusMember != 'disconnected') {
        console.log('Time out');
        this.socketMember?.close(3001);
        this.connectionIdMember = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoadingMember = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  closeSocketWithTimeSummary() {
    this.socketConnectTimeDR = setTimeout(() => {
      if (this.socketConnectionStatusSummary != 'disconnected') {
        console.log('Time out');
        this.socketSummary?.close(3001);
        this.connectionIdSummary = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoadingSummary = false;
        this.gettingSummary = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  test() {
    this.callSummary();
  }

  test2() {
    this.connectionIdSummary = '';
    this.updateCIdSummary = false;

    clearTimeout(this.socketConnectTimeSummary);
    this.searchLoadingSummary = false;
    this.gettingSummary = false;
    this.socketSummary?.close(3001);
  }

  search() {
    if (this.displayMode == 'Daterange') {
      // this.searchWithDateRange(false);
      this.connectWebSocketDateRange();
      this.closeSocketWithTimeDR();
    } else if (this.displayMode == 'Absentreport') {
      // this.searchWithDateRange(true);
      this.connectWebSocketAbsentReport();
      this.closeSocketWithTimeAR();
    } else if (this.displayMode == 'Location') {
      // this.searchWithLoc();
      this.connectWebSocketLocation();
      this.closeSocketWithTimeLOC();
    } else if (this.displayMode == 'Project') {
      this.formSubmitted = true;
      if (
        this.getcheckinForm.projindex == '' ||
        this.getcheckinForm.projectid == ''
      ) {
        this.messageService.openSnackBar('Project cannot be blank.', 'warn');
        return;
      } else {
        this.searchByProj();
      }
    } else {
      this.atFormSubmitted = true;
      if (
        this.getcheckinForm.userid == '' ||
        this.getcheckinForm.userid == null
      ) {
        this.messageService.openSnackBar(
          this.getcheckinForm.membertype + ' cannot be blank.',
          'warn'
        );
        return;
      }
      // this.getCheckInList();
      this.connectWebSocketMember();
      this.closeSocketWithTimeMember();
    }
  }

  sortData(sort: Sort) {
    const data = this.daterangeData;
    if (!sort.active || sort.direction === '') {
      this.daterangeData = data;
      return;
    }

    this.daterangeData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'days':
          return this.allinoneService.compare(a.days, b.days, isAsc);
        case 'hours':
          return this.allinoneService.compare(a.hours, b.hours, isAsc);
        case 'totalhours':
          return this.allinoneService.compare(
            a.totalhours,
            b.totalhours,
            isAsc
          );
        case 'iohours':
          return this.allinoneService.compare(a.iohours, b.iohours, isAsc);
        case 'absence':
          return this.allinoneService.compare(
            a.absencedays,
            b.absencedays,
            isAsc
          );
        case 'leave':
          return this.allinoneService.compare(
            a.leavecounts,
            b.leavecounts,
            isAsc
          );
        case 'rank':
          return this.allinoneService.compare(a.type, b.type, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'divisiion':
          return this.allinoneService.compare(a.divisiion, b.divisiion, isAsc);
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        case 'date':
          return this.allinoneService.compare(a.date, b.date, isAsc);
        case 'location':
          return this.allinoneService.compare(a.location, b.location, isAsc);
        case 'absencedate':
          /* Returning undefined. */
          /* Returning undefined. */
          return this.allinoneService.compare(a.date, b.date, isAsc);
        default:
          return 0;
      }
    });
  }

  sortLocData(sort: Sort) {
    const data = this.locShowData;
    if (!sort.active || sort.direction === '') {
      this.locShowData = data;
      return;
    }

    this.locShowData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'days':
          return this.allinoneService.compare(a.days, b.days, isAsc);
        case 'hours':
          return this.allinoneService.compare(a.hours, b.hours, isAsc);
        case 'totalhours':
          return this.allinoneService.compare(
            a.totalhours,
            b.totalhours,
            isAsc
          );
        case 'iohours':
          return this.allinoneService.compare(a.iohours, b.iohours, isAsc);
        case 'absence':
          return this.allinoneService.compare(
            a.absencedays,
            b.absencedays,
            isAsc
          );
        case 'leave':
          return this.allinoneService.compare(
            a.leavecounts,
            b.leavecounts,
            isAsc
          );
        case 'rank':
          return this.allinoneService.compare(a.type, b.type, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'divisiion':
          return this.allinoneService.compare(a.divisiion, b.divisiion, isAsc);
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        case 'date':
          return this.allinoneService.compare(a.date, b.date, isAsc);
        case 'location':
          return this.allinoneService.compare(a.location, b.location, isAsc);
        case 'absencedate':
          /* Returning undefined. */
          /* Returning undefined. */
          return this.allinoneService.compare(a.date, b.date, isAsc);
        default:
          return 0;
      }
    });
  }

  sortAbsentData(sort: Sort) {
    const data = this.absentData;
    if (!sort.active || sort.direction === '') {
      this.absentData = data;
      return;
    }

    this.absentData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'days':
          return this.allinoneService.compare(a.days, b.days, isAsc);
        case 'hours':
          return this.allinoneService.compare(a.hours, b.hours, isAsc);
        case 'totalhours':
          return this.allinoneService.compare(
            a.totalhours,
            b.totalhours,
            isAsc
          );
        case 'iohours':
          return this.allinoneService.compare(a.iohours, b.iohours, isAsc);
        case 'absence':
          return this.allinoneService.compare(
            a.absencedays,
            b.absencedays,
            isAsc
          );
        case 'leave':
          return this.allinoneService.compare(
            a.leavecounts,
            b.leavecounts,
            isAsc
          );
        case 'rank':
          return this.allinoneService.compare(a.type, b.type, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'divisiion':
          return this.allinoneService.compare(a.divisiion, b.divisiion, isAsc);
        case 'absencedate':
          /* Returning undefined. */
          /* Returning undefined. */
          return this.allinoneService.compare(a.date, b.date, isAsc);
        default:
          return 0;
      }
    });
  }

  sortProjData(sort: Sort) {
    const data = this.projData;
    if (!sort.active || sort.direction === '') {
      this.projData = data;
      return;
    }

    this.projData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'hours':
          return this.allinoneService.compare(a.hours, b.hours, isAsc);
        default:
          return 0;
      }
    });
  }

  typeChanged() {
    // this.getcheckinForm.subtype = event.target.value
    // this.getcheckinForm.subtype =

    if (this.getcheckinForm.subtype == 'Employee') {
      this.barChartData = [
        { data: [parseInt(this.summaryData.total)], label: 'Total' },
        { data: [parseInt(this.summaryData.usertimein)], label: 'Time In' },
        { data: [parseInt(this.summaryData.useractive)], label: 'Active' },
        { data: [parseInt(this.summaryData.usertimeout)], label: 'Time Out' },
        { data: [parseInt(this.summaryData.usercheckin)], label: 'Check In' },
        { data: [parseInt(this.summaryData.useractivity)], label: 'Activity' },
        { data: [parseInt(this.summaryData.userother)], label: 'Other' },
      ];
    } else {
      this.barChartData = [
        // { data: [parseInt(this.summaryData.total)], label: 'Total' },
        { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
        { data: [parseInt(this.summaryData.timeout)], label: 'Time Out' },
        { data: [parseInt(this.summaryData.checkin)], label: 'Check In' },
        { data: [parseInt(this.summaryData.activity)], label: 'Activity' },
      ];
    }
  }

  downloadSampleFile(): any {
    this.http
      .get(this.allinoneService.bucketUrl + 'templates/SampleTemplate.xlsx', {
        responseType: 'blob',
      })
      .subscribe((response: any) => {
        saveAs(response, 'SampleTemplate.xlsx');
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  downloadLocSheet() {
    console.log('call');
    // console.log(this.locfileurl);
    // return this.http.get(this.locfileurl, { responseType: 'blob' });
    var startd = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.formatToShow,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.formatToShow,
      'en-US'
    ).toString();
    var name =
      this.getcheckinForm.orgname + '(' + startd + '-' + endd + ').xlsx';
    var exdata: any = [];
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet('Sheet 1');
    var filteredLocData = this.filter.transform(
      this.locShowData,
      this.searchTextLOC,
      15
    );
    filteredLocData.map((item: any) => {
      var temp = {
        EmployeeID: item.employeeid,
        UserID: item.userid,
        Name: item.username,
        Department: item.department,
        Division: item.division,
        TeamID: item.teamid,
        Type:
          item.iotype == 'in'
            ? 'Time In'
            : item.iotype == 'out'
            ? 'Time Out'
            : 'Check In',
        Date: this.allinoneService.formatDBToShow(item.date),
        Time: item.showtime,
        Location:
          item.location == '' && item.lat == '' && item.long == ''
            ? 'Unknown Location'
            : item.location,
        LatLong:
          item.lat != '' && item.long != '' ? item.lat + ', ' + item.long : '',
        Organization: item.orglocation,
        LocationType: item.locationtype,
      };
      exdata.push(temp);
    });
    workSheet.addRow([
      'Employee ID',
      'User ID',
      'Name',
      'Department',
      'Division',
      'TeamID',
      'Type',
      'Date',
      'Time',
      'Location',
      'LatLong',
      'Organization',
    ]);
    exdata.forEach((item: any) => {
      const row = workSheet.addRow([
        item.EmployeeID,
        item.UserID,
        item.Name,
        item.Department,
        item.Division,
        item.TeamID,
        item.Type,
        item.Date,
        item.Time,
        item.Location,
        item.LatLong,
        item.Organization,
      ]);
      // for (let i = 1; i < 10; i++) {
      //   const col = row.getCell(i);
      //   col.font = {
      //     color: {
      //       argb: item.LocationType == '3' ? '000000' : 'FF0000',
      //     },
      //   };
      // }
      const col = row.getCell(10);
      col.font = {
        color: {
          argb: item.LocationType == '3' ? '000000' : 'FF0000',
        },
      };
    });
    workBook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, name);
    });
  }

  downloadAttendanceSheet() {
    this.downloading = true;
    var attendanceSummary: any = [];
    var name = this.getcheckinForm.orgname + 'AttendanceSummary.xlsx';

    var date = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.formatToShow,
      'en-US'
    ).toString();

    var temSummary = {
      Date: date,
      Branch:
        this.getcheckinForm.branch == 'All' ? '' : this.getcheckinForm.branch,
      Department:
        this.getcheckinForm.department == 'All'
          ? ''
          : this.getcheckinForm.department,
      division:
        this.getcheckinForm.division == 'All'
          ? ''
          : this.getcheckinForm.division,
      TeamID:
        this.getcheckinForm.teamid == 'All' ? '' : this.getcheckinForm.teamid,
      'Cost Center':
        this.getcheckinForm.costcenter == 'All'
          ? ''
          : this.getcheckinForm.costcenter,
      'Sub Division':
        this.getcheckinForm.subdivision == 'All'
          ? ''
          : this.getcheckinForm.subdivision,
      'Check In': this.summaryData.checkin,
      'Time In': this.summaryData.timein,
      'Time Out': this.summaryData.timeout,
      Activity: this.summaryData.activity,
      'No Activity': this.summaryData.noactivity,
      Absence: this.summaryData.notimein,
      Leave: this.summaryData.leave,
      'Total Employees': this.summaryData.total,
    };

    attendanceSummary.push(temSummary);

    this.allinoneService.exportEcecl(attendanceSummary, name);
    this.downloading = false;
  }

  downloadSummaryWithBranch() {
    this.downloading = true;
    const name = 'ILBCAttendanceSummaryWithBranch.xlsx';
    var ddmmyyFormat = 'dd-MM-yyyy';
    var date = formatDate(
      new Date(this.getcheckinForm.startdate),
      ddmmyyFormat,
      'en-US'
    ).toString();

    this.inbranchList = [
        {
          "userid": "khinmarsoe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "khinsandaraung@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959798840172",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "maythuhtet@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "mohmohnaing@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959985232123",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "nwenwewinmdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "theingiwinwin@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "maythazin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959789648985",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "tdaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "eichozin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "thetthetswe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thuthusan@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nyeinhsuwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "maylwinoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 4"
        },
        {
          "userid": "pyaepyaephyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nyeinchanaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "waiphyo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "panwaiwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "yunnphyusinthant@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayeyarwaddy@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "myokyawthu@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "eieikhaing@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "maypyaeoo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "khinyamonephoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "soemoekhaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thazinphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khinpyonemar@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayemyatyadanarsoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thazinmyintaung@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinkyikyithaw@ilbcpreschool.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "hninhninwai@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "kaungmyat7410@gmail.com",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "amemeoo@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "aungkyihtwe@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "kaungthantzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nwayhtwehlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinthidawin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "zinmarmyint1@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yoonkalayarkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayemyatmyatmyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thinthirihtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thirimyathsu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myothiriswe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "soemyatmaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sulabtnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mayoonaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winzarchiaye@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sawaungnaingsoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "phyoeinwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shweryiwinkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thazinhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminsuhlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eishwesin03@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "hninwutyee@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thitthitsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nanayemyatmyatphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yinyincho@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawlaylaytun@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959987463509",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thidawin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "memeaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungmyomin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yadanarphyowai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "julynway@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "shunyatizaw@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "wuttyeemyomyint@ilbcpreschool.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "zuemyatnaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "htetsupaing@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "yeeyeemyint@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "khinesuwai@ilbcpreschool.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "sawmoses@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khunnaymoeaung@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "kyawthantkhine@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayeayekhaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "htethtetwintphue@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "chawmonpyae@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "yoonmheoo@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "myatthandarhsu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieikhaing2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maylattsan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninyeemon@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "suleiyee@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959774777679",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yusandarsoeaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "maykoko@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "drhsuhninswe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khaingthazinhan@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayekaday@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khaingsuwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "dr.khinmyomyint@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "htetwaiyan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "pyaephyoaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "wintshweyee@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hanhtookoko@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nandazan@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "myatwuttyee@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "khinthidarmaunglatt@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "khaingthazinmoe@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "khaingphoongone@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sweswemoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thetthetwai2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sandaraung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "myothurakyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yamonenarko@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "wailinthant@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "zayarlynnnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thuyeinnaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "yadanamyatsoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nuwailwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "pwintphyopaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 2"
        },
        {
          "userid": "htethtetwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "kyawhtetaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "drsaiayemon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "tinsabainyein@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "jahtoi@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "thelsumaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "eieimon@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 4"
        },
        {
          "userid": "thidatun@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "moemoekhaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kaythiwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "phyothandarlwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "htethtetswe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "kyisin@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "lwinlwinkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sandarmyint@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nyeinnyeinaye@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "haymarhtun1@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thandarphyuphyuchit@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 2"
        },
        {
          "userid": "lwinlwinthant@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eieiphyo1@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "suzunelay@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khetthitsar@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "sawmoeaunghtoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "paneiphyu@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "soemoethan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thithishwe@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "aikeminnandar@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "nangthweyephyo@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "htayhtaywin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "twaltartun@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "sulattphyo@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "zarzarmaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "maymeemeemyatmon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "kyawtphyukhine@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "myolinhtet@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "susandilin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yadanatheingiaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yadanarhlaing@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "khinthetswewin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "poesandihan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "winthandar@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "+959791228645",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "theinttheintthu@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nwezinkham@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "+959257166100",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "suwarmyint@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinmamaoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thuzarhnin@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "ayeayeaung@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "wainesandarko@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "nuwaiwaiwine@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinmyatnoeaung@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959974219353",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "tawwinnyeinchan@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khaingthidaswe@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "thithiaung@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eitarmoe@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "nangeiphyusin@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "sandarphwephwe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "+959427889972",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "htethtetkhaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "nwenimoe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "zinthusoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thandarlwin@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "myintmyatthu@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "khaingphyooo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nilarwin@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "thandarsoe.tgi@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "zwemantun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maythetpaingphoo@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "jalainaung@ilbcpreschool.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "hlamyothu@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nwenweayemdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "+959753014353",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "nwaynwayhlaing@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "ayesumyat@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 2"
        },
        {
          "userid": "hninhtetwai@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "phyuphyuhninoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eaintyadanar@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "shweyeechit@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "aungmoehtwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "linlinkhaing@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "+959785795597",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "shweyeehtayaung@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khinmyothet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinzinkhaing@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "+959771523001",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kyawtzinlin@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "thaesuwai@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinmyochit@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "suhlaingwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "madimyintaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "winnandarkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "juepyaepyaewah@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidaphyonyein@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "thirikyawmdy@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959256008431",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ktwai@yis.edu.mm",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "zarzaroo@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yeeywalphyo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "zinzinei@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "mihtetyadanarmon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "naingnaingmaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "mawmawlwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "thuzartin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "chawsuwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "theinttheinthtun@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "pausawnbawi@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thiritun@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "eishwesinmyat@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayethantzaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yuzinnway@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "zinmarmyint@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "eiphyuphway@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nawphawehmoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 6"
        },
        {
          "userid": "tinhsuwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "aungmyowin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thawthawpaw@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "htetwaioo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eiphyuhtun@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "winhtethtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thazinmoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nangthandarhtun@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "naychichocho@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "moethazinlinn@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "myatswemon@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eiphyukhaing@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayenyeinthu2@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "ayezinmaraung@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "nangkhinlinsandar@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "tinnangsoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "waishoonyi@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "thetmyatnoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "sumyatphway@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shweyeehtet@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "hninwaihtun@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "eieimoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nguwarhlaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "thetzawhtwe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "+959973125676",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959977529721",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "ayemyatthuzar@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "eithinzaroo@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "myasabaltun@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ssphyo@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "hnineiko@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayeayephyo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "phyothandarmyint@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "hanniaye@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "myattheingi@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yamonephoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thanhtetphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "pyaepyaeaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nilarwin2@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "maythetpaingoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ayemyatmoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "shwezinphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khetwaihtun@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hninzunpwint@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "maymyothan@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959797775618",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nannhommo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "phyoeikhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinmarwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 2"
        },
        {
          "userid": "hsuhtetmon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "waiwaikyi@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "myatpyeipyeisoe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "thaesuaye@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khaingzinnwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinsanwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yinyinkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hninwaihlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyusinoo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "+959962071129",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "arkarmyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayechanmoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khaingkhaingmaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hninwaiphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "sumyatnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "poeeiphyu@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "maythuzaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "phyopyaepyaekyaw@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "maythuhtethtetoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "sanpapa@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymyatnoeswe@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "myothandarwin@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "julymoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eimoehlaing@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "lnangsan@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "suphyotheint@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "eimyatmoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatsunandarkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ayethandarmyo@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "wutyitun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaytharizaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nangphupyaehlaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "kaykhaingphyo@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "soecherrynaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "maythinzarlwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "nguthinzawphyo@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "myintmyinthtwe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nilarmyint@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "shweyeewinlae@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "theintphyowai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "nwetyinyinwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khaingzinmarlin@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "nweyeelin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "thuzaroo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yadanarlwin1@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "kyipyaroo@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "knthant@yis.edu.mm",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "toeaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959788411453",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinhtetmonzaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959402045205",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "mumuthein@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ayemisan@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "hannyhtun@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "memeyoon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "myohlaingwinn@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khinsandartun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htetthirinaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "chothaemon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "eieizin@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 3"
        },
        {
          "userid": "ayethiriphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ayemyatnaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 3"
        },
        {
          "userid": "khantyinminswe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yoonyamoneoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ayeayethwe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "waiwaihtwe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "wahwahthin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "hunnyhtun@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nilarwin.lso@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "khinezinthin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nwenitheint@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "myathazinnaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayenyeinzaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "aingyinkhine@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayechanmyaemayban@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "monmonthan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "sumyatmoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "sumyatnaing02@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "zarchiphyoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "papawin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "thazinoo@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nunuhtwe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "moetunzaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nandakyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sweswemyat@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "chuechuemaysan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "+959981718771",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "myoaung@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "nanookhaing@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "+959770500727",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959961042726",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yamintheint@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "mzsoe@yis.edu.mm",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "kayzinthwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myatthirikyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thinthinkhaing@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "thetwaiphoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nwenweaye@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "naingaunglinn@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khinzarhlaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "phyokhantmg@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "hannweoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymikhin@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nanayepyaewon@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinchochooo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thandaroo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "tartarlwin@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 6"
        },
        {
          "userid": "hsushweyeehnin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nanglaomoon@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "shanekhant@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eilaynwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thidawinmdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "hanminsoe@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "noenoeaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "htethtethlaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myosandar@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "shwephuekhin@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "ayepwintphyutgi@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "zunmyintthan@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "kyawaungaungoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "susunaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "chawsu@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thandarsoemdy@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959255611817",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "zinlinnoo@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "naynwemyo@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "kyawtyoonpaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nangzinmarwin@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "mayzinoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "htetwaikhine@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayethandasoe@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "phyophyowai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "nyomiekhaing@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "mgthant@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "winmyatthu@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "nawthaeeainhtoolwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959794706002",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "saunghaymumnko@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "mmaw6135@gmail.com",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "yumonthet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shweyeeaung@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "ayemarkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "+959265211014",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "chanmyaephyu@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "soetheinttheintoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "tintinkhaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "suhtwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "sweswezin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "laura@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "mayphuthazin@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "myothadarhtun@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nanthidalwinmwa@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "phoopyaepyae@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminthawdar@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "naychiayethwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawalicemaryjue@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "ingyinmoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsuyinhtike@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "yusabaithin@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "myintmyintsan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethandarhtun@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "linkokohtike@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "dr.khantkhantchaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "kayrapaw@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "hninsuthet@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "shwesinhtoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "sumonkyaw@ilbc.edu.mm",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "khaingzarlinn@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thandarkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "theintkoko@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninpwintphyu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "swezinnwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninnuwaimdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "myamyintzuaye@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "ninishwe@ilbc.edu.mm",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "ayethirioo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "thaethaeoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "mayhsumonmdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "maypalaethwe@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "yiyimaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hsumyathtet@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thukhapyaesone@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959941209137",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinelynnhtwan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "dr.thidaaye@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinezarmon@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "meemeetun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayechanmyamoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinwailwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "kyizarwin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "eisuphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khaingzinwai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtetkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "soesatsatkhaing@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayethetmaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "saphyothetpaing@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "kyawnaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nwaynandarhtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mipanchanthike@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "einang@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "pwintwarkhaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 4"
        },
        {
          "userid": "nyeinnyeinsoe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khinkhincho@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "thuzarwinmdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "+959987783283",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "htethninwai@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "chityaaung@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "eimonaung2@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "htethtetzaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nilaraung@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "minzawoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winmohmohaung@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "wintphyopaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "theintthinzaryi@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "seintseintzawpo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eizarphyu.hr@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "sumyatmyatkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shuneleileikyaw@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "mohthant@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htetwutyihlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "minsettpaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "soemyatwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyawtkaykhaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "panei@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "shweyeewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thomasthein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninoowityee@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nangkhamaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "meemeeayechanmay@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eiphyukhaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "pphyuthan@yis.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "hninwaiphyoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "eiwaimon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymemethant@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "eihtweoo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "maythura@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yiyimyint@ilbcpreschool.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "ingyinsan@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "jinniewindy@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinmyatwutye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyawswarlin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "haymarnhnin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myintzuwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "hninnsettwonzayar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yeeyeemyoaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "wahwahnaywin@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "tarhsogay@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "maypaingoo@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "thihazaw.twn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungkyikyikhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zuezuethant@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "kyawkyawoo@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "sawyuyadanar2@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ywesunaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khaingwarphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "myatthinzarko@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "yaminthu@ilbcpreschool.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "sawyunwezin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959261950055",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "+959798637794",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "laelaewin2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959406618172",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "tinnilarnyein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinmarwai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinmohmohtun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nyanlinhtut@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mayyeewin2@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "zinmyonwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winyunandarlin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayekyizin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nilarwin.pe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phoophoothant@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "naingwinkyi@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "hninhninphway@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "htethtet@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nayyeewin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "maymaynaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myopapa@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "yuyumon@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "soezinphyo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "haymannkoko@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "soehtetthu@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "winthawdaroo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "zuezuechit@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "yusandartun@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "lineieizaw@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "ayemyatmon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "thuthuaung1@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "poeeilinn@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "maymyatmoelwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "zunekowin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nna@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thukhamintun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "razaroo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yamoneoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eieikhin-hr@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "winpapakyaw@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "linlettlettphyu@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "pwintakarikyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nangshwesinmyint@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "ayeyadanarminaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "wineieisan@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "winwinlai@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thetmonsan@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "khinhtethtetnaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thuthuwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "sithushine@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "hninmayaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieikhin2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinnwe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "nawlayphaw@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "+959253391105",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "yuyuhtwe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "shutin@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "yeehtwe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sudarlimaung@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "sawhsarpale@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "nwayyuzun@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "trmohmohaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinthuthu@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "htethtetnyeinmdy@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thazintun@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "wutthmonemoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "wahwahtint@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thethtarnwe@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "aungtunoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidaaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtetnandarnyo@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "ayet84907@gmail.com",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "zarzaraung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thandarhtun@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yeemontun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thihayekhaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawlahmaypaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nwemarwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayesabal@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "sawtheinkyi@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayemyotthu@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "waimoeaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyiyadanarhan@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thinmalar@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thaenukhaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "maythuzarkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "hmuthantnandar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyawtshinwai@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khinekyawtkyawtseinn@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatthiriwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thetwaiwaizin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eipaingpaingphyo@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "sandaroo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shwephuehmone@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "winhla@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinezinwin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khinkyawtkyawtshinn@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "mayzawlwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "aungkyawhtay@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "warwarhtike@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "eieiaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khantchawsu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winleiphyu@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "nwaywar@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "nangkham@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "wintwahhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "yeaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "htethtetwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thuzarko@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 4"
        },
        {
          "userid": "kyarnyothwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thinmarhtay@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yexpaunglin@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "eaintthethmue@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatwutyee@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "matthewthelreh@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maythukyaw.myk@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "nawjulyshweba@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 3"
        },
        {
          "userid": "sheetharbo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "eiyadanarmon@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kokonaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eishwesinmoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "naylinnaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "phuupyaesone@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsanhtwe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "winyeechan@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "maymiekyawlynn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatthazinkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yoonlaelaewin.twn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yinyinkyaw2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "michannon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninwyutyi@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayemyintmyintthwe@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khaingkhaingwin2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinsandarwin@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "linsandarmyintthein@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "myintzuaye@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "htaykyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hsunweaye@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thandarkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "okkaaungnaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawelbaluhtoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "phyophyo.fo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "lynnzarchimaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nuwahwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thandarswe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "muekhophaw@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "khinezarnaing@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayeayekhaing.twn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "cherrywin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "+9592061540",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayesanoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinbhonemyint577@gmail.com",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "marlarwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninhninhtun@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "myomyozin@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "sawyumon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "drshwesinoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "phyoeieibo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "sawyunwe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htarsuwai@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "thirinwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ethelmdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "thwindichitsu@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thandarnyunt@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "mimikyaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "theinttheintthu2@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nilarshwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myintmyintkhaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "eieithan@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "mumukhinehan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959787620902",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thinthinmyoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nonotheint@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myintkyi@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "channyeinaye@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "zinwarphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thinyeetun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yoonthinzaraung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "shoonleikaykhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieithein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyalsinzaythaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "myakaykhaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "mayzarzarhtet@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nanghsengkhammoon@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "khinthazinaunt@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959679468405",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nilarwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "maythazinko@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "ayenyeinthu2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thiriyadanar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "wahwahkhaing@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "zinmayoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thazinmon@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "hsuthinzartun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959425018503",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sawmarlarhtun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "suthetmuetin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thazinminhtet@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "zinmaroo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "witmhon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyuzarlin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "sisizaw@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "laelaekhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinthandamyint@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "ayethandarlatt@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eimonmonmyo@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "khinsandarlin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayeayethaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "linlattzaw@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "winnaunghtun@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "hninwaiaung@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nanmkkhinehlyan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "eithazinkyaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "heinhtetwin@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "rosesalinsai@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "htethtetthaungwin@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "aungaungoo@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "florence@ilbc.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "eieiphyoe@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "myintzuthinn@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "htethtetlinn@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "myatzawkhant@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thethtarswe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khaingmyatnoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kyikyiwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "kyukyunwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinpwintphyu.kg@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinmyolwin@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "yadanaphyu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nwetnwetwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yemarnaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsuwaddynwe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nawthitsaraye@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "nandayekhaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "zinmaraye@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "eieimon@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "shweyeeoonpt@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "tinhtarswe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "soesandarlin@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "ayemyatsumon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinmayphyuoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayejulymon@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "zarlekhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "soehtwe@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "moepyaechanthar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khineaindrahtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinzin@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "yatiphuepwintnaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "thithimaw@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "yoonwaddykyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thinzarwai@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "naychi@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "sawyuhninnwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thandarwai@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "honeyhsuzin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "zinmarhtun@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eithuzaraung@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "marymyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninhninwai02@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "tayzayekhaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawjulaypaw@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "suwaitun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thetthetnwe2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawehpawwah@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "zarnithantzin@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "thetmaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zarniwin02@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "nantpyoneakarisan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sandaaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinnilarwin@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "aungaung1@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinthazinhtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "haymarwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yeeyeekyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "zinmarphyo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khanleithar@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "thirisan02@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "zayarminnaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "suhtethtethtun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "phyowaioo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yuyumonnpt@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "nawrjweeloo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayeayeyee@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myothantzin@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "htetthinzarmaw@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "phyuphyulwin@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "waizinmon@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thidaraung1@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "naingnaingyi@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "santhirihtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yamonehanwinhtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinmimi@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myintmyintwai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayeayeaung.kg@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maythukyaw@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "hsuthinzar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "nawkhinlaymyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hlayaminthu@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "phyophyo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thelnuaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinmayzin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959797623507",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khinmoewai@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "wahwahaye@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "aungkyawpaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thephyuwinkyi@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "thinzarhein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nansukalayar@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "pyonepyoneaye@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thetthetswe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959788539294",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayemyasandithin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "maungmaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "+959252067834",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "suwaiphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thawzinhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymyatwaithwe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "channyein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mememyintswe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shwesinoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyumarhlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "dimsianlun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "chawsuzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "phyopapakyaw@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "suyati@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawmayzinphaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtetphyu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sueizaw@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayemyat@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thanthanhtaynpt@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "yinyinhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yinyinhtun02@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinmarphyoe@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "ayethandarmyo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "maymyatthandar@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "thwethwehtoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hninyuyu@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "thanlwinmaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "kyawtyaminnwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninyadanarwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "yeyintaungmwa@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "hninwailwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninhtethtetsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sawshonpyae@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "chochonaing@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "maythulwin@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "eieikhaing003@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "chitsukhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nyeinchanthu@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "hninthiriaung@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "hlaingyadanarmoe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "thaesupaing@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "sandarlinn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "naneieikhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shweyisoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethazin@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "khinlayphyu@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "tinlinaung@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "hninwuttyioo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+95943133084",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "chawyunandar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "laelaewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtetkhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawhelena@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "niniwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "julymoe02@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "woohtut@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "nanglaolinn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminaye@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "suyadanar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieiaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maysandar@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "linnthandartoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayechanphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "lwinlwinkhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "miemiemoezawwin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "hsusandarhtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "haymanoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "yoonmyatnoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "theintheintun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959978422680",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayemyalinn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsupaingthet@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "myominkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nilarsoe@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959798868274",
          "branch": "Mandalay, PreSchool 3"
        },
        {
          "userid": "moemoeaung@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kmn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sunandarhnin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thandarhtun003@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thandarhlaing@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "nawruthsahpaw@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "thinmyatyadanarsoe@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "mohmohmyintthu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawwahwahsoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "suwaihlaing@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "phyusinphwe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959425016626",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959250658409",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959781783374",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myintmyatthu.twn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaungmyatnaing@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "mayjulymyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminswe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myathidarhtun@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "chochonwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sulinhtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninwintwintkhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thawzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sabaiphyukyaw@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "sawkaeblutemue@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "nilarlwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "shinelwinaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thuzarwin112@ilbcpreschool.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "thidamaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "pyonethidatun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maythazinkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinthetmon@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "kyawthuhtwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidahtay@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "thazinkhaing@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "maynwesoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "eithandartheint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinzinmar@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thitthitoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "ssengja@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yanlinnnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminhtun@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "ayenyeinsu@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "sherotun@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winnandarhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "lydiapoepoe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "yegyan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htetyadanaroo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungkyawthan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "khinchawpaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959421047580",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thandaraung.prm@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "tinmaysan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "chochothett@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "poethuhan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yoonkaykhine@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khinzaroo@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "naylinntun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thaesuwintaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nangkhamkhaung@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "yuyumaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thidawin2@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959650355569",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thaesumyatsoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "htikehtikewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayeayethant@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khinemayaye@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "junerose@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thidarkhin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thethtarmon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maythetmon.tmg@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "wunnawin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959773989785",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "thandarnaing@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "khaingkaungsan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "elizabethsan@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "hninthetnaing@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "maymyatnoeoo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "thiriwintwar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ingyinkhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yuparayemyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thaephyukhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hlamoeoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinnwenwesoe@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "khinecholatt@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawhighest@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "khinthiriswe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsuhlaingphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinhninhninaye@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyisinwinthant@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "winwinmyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htangsan.lso@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "thiritun.tgi@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "tinaungkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mayhansett@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawkweelarshee@ilbc.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "poeeiphyu.twn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khaingzinthet@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "yewinnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myathetthetkyaw@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "khinkhinlatt@ilbc.edu.mm",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "myothiriko@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thetmar@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "thynnkhwarnyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinmaungnyunt@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thawzinoo@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "aungmyinthtoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myosan@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "hninwityiwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yunandarkhaing@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "yoonshweyee@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thinthinmon@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "msnow@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nawpawsaw@ilbc.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "nannwenwehtay@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "laylaymyint@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khinnyeinchan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kayzinthwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymyintmyat@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatthiriphyoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayesumon@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "yehtetnaing@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "ayepapalwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shunnletletoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "panneihlaing.tmg@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "thiriyin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "pyaethweaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "ohnmarsan@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "lwinlwinhtay@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "winpapaphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myintmyintzaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hlaingyamintun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myothuzar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thanthanohn@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "maythutun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinthidalwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959421487794",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "hsuhlasandi@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959794543412",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "ingyinmay@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "theintdarlioo@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "nanghtwe@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "ayetheingikhaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinecherry@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nilar@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "aungmyintmoe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "thetmonaye@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "kalyarthinn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayenyeinsan@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khaingzawlin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thuthukhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thuthuaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thidalwin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nyeinnyeinaye02@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "sawhninthandar@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "soesoehtay@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "nwaynwayaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethinzaraung@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "sumonaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nangjar@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "phyothizarmyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mohmohaung02@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "myatmon@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "nawehbeenar@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "maypaingsoeoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "waimezinzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "phumyatthwethwe@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "zarchihtwe@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "thaethaetone@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "wutyeephoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hansoethu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyawtmuhan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sweswewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninpwintphyu1@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyipyarphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyusinhtet@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ninikhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayeayemon2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959790360590",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tharapheaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sanminminoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinsandisoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "praisingmootaw@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayemyawin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "tinmarwin@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "nyanzaylinnhtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959451157516",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "htethtetkyawnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sumonhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinthazinnyein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yinminthwe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayethetsan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "saintyamoneoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "lelekhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sandisoe2@ilbc.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "aungmyintmo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nilarkhin@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "ayenyeinthu1@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinmoeaung@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "theintyawaihlaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "eikhinkhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaungmonhtike@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninayewai02@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "wutyeyelwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959775238052",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieikyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maylapyaeko@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "mary@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959781122892",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959969870250",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959250002607",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sawwinhtutoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "yekyawmoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayemyatmon02@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinthwethwemyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zarchilin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinleilei@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khintheingilwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "drsulwinkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nawkeekupaw@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "mikhinsuhlaing@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "thaemohmoh@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "nawyuzana@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyawzinthet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959782363861",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eipyaethwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "lynnhtetnaing@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "ekmaung@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thandarsoe2@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "nawhlamyatsan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "aungkaungzaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinethazinaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyawmgmgchanmyae@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yadanar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatyadanaroo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mmphyo@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nunuaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959752494040",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sandarhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "warwaraung.kg@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "+959954036043",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtethlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawlinlinaye@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "mayphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "moethazin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayeyukhant@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sususan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yeemonhan@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "winthan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959421109978",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "shwezinmin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "moeminzaw@yis.edu.mm",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "pyonepyone@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtet@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "eishwesin2@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "winthandarhlaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "sabaloo@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "naychikhinzaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "hnineaindra@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959401177553",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "tintmoezaw@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "thinnshweyi@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mayhsumon@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "khinsuzin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "michaelboi@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "hninthazinkhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nyuntnyuntzan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "wuityeephue@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminkoko@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "setpaingkyaw@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "amtcin@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "khinhtarhtarthwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yeminaung@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "yinmoeaye@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayeayemon@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "s.brownstalin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "nantmudiana@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "nyeinchanthuthu@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "tweltar@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "aungkyawthet@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "sylviachit@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "nannohnmarwai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sumyataung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shweyiwin.npt@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "nyanmyinttun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959420193994",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nawsaypalehhtoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thetthetkhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidaaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ttthein@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thaesu@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "zuezue@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "yadanarthein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mmsoe@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nwai@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "theingilwin@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "angeline@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "moethinzarmyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yuyuhlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "kaungmyatsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htayhtaywai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zarninwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "soesoethant@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khatkhatzaw@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "mimisittmoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eieiphyo.kg@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethteteihlaing@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thinthinkhaing.ircd@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yamonehlaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "sengmai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "panneiphyu@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "nguwahcho@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "belle@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyuphway@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawcatryna@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959254127019",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "sumyamon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mzoo@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "hhmyint@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "zinminnoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myamyahtun@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "kmnwe@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "ououkhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959965388853",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "kyawzinhtet.pe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kwh.htoo@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "khinehsuwai@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "michellezaw@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "eipwintphyu.ilbc@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "wintthingyansoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "htayhtaykyawe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "kyawtkyawtnaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "paneiphyu2@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "shwethithihlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aaaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "mohmohsan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959403937929",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winhtetlin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yadanarmoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kathy@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "eaintzarchikoko@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungzaylin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nantunisoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sawpaul@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayemaymyathtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nyeinnyeinei@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959770045069",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thetsuswe@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "aprilthein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thuthuzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "twphyo@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nwenweoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyuthihtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "supyaesonewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959450023702",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "tinsandar@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "kyiletsuhan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinemaylatt@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yaminthu02@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtetsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nyeinnyeinhtwe@ilbcpreschool.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thinzarmon@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawayethandarmyo@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "ayeayethan@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "kokomin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nankhanpo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "zinlinhtwe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "nilarhan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959253244766",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "moethidatin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "maymizin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khinzarchitun@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "hlawuttyee@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "kyizarnwe@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "+959980480105",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "moewaiaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayeayeaung3@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "thithinyein@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinzarnihtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yinyinswe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yamonezin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eikaykhine@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "sanlin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yanmyonaing@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "kswai@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "ayechanmay@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "malarthan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "kyinkyinhtwe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "dsaw@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thazinhtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungmyooo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "ayehtetwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "chitpyonelaewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959420018099",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959769397083",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959967905516",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thawzinhtut@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawkyikyikhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myahtethtetkyaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "amethyst@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "mnilar@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "soeooshwe60@gmail.com",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "waizinkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsuhlamo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959773046181",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "hlahlahtay@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "linlin@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "precious.pawkhu@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "maimyatthirilwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maythutaaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "eieiphyu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "moepwintphyu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayemyatlwin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nwenweyee@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sagawarhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "honeytun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thuzarsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinzarchikyaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959262979108",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "phyomayoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinmayoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawpawhtoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myintmyintkyi@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sunandarwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "ayethandarhtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htetmonoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ehkaythaw@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "hsumyatnoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959253245115",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "mayphyothu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayezarwinnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayechanmyaehlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungzawzaw@ilbc.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nadishweyeeoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninhninyu2@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayechanmyat@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "eieikhin.it@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yinyinnwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mayyi@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "zuezuekyawzin@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "ayeaye@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "myonaingoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "pthkha@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "maymonkhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "moemyintthazin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nansusupan@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "khinayemi@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "pyaepyaephyo02@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatsoethet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myateimon@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "myokhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959404598208",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "minminoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phoomyintmyatchal@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "swesweoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nunulwin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "pwintphyu@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "thinthinmyint@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayeayekhine@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "shinethinzaroo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayesandartun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "lahmaypaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thuzathaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eimyatmonchit@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shoonhtetsan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaungsett@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "khinzarni@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maypoeisan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nayaungsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "santhitpaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "arrmahnwint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "maythu.htet@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "mthida@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "yunandarswe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "phyumarsan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eieikhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinyadanarmaungmaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htoohtooaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "phyuphyuzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "moethauk@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "suleiwin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "yemyatthu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thithiaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "waiwaikhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsuthethtar@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "yoonhmehme@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaykhine@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "thinthinsoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "phyuhninei@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "htethtetlinswe@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "+959266232851",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nyuntnyuntkhin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "zinmarlwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninnandaraye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyawyemoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "+959792519632",
          "branch": "Mandalay, PreSchool 3"
        },
        {
          "userid": "winminthan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yeeyeecho@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawjudyyin@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "tinhtut@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "drthihaphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "zarchilinlin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yadanasoemin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959421110337",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "htetnandarlinn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tina@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "phyuphyunyein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayejulykoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959976103996",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959254186700",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thuzarhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959421174515",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959766620175",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "inngyinmyatmon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "theingiwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "+959956980351",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959787095099",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959445759785",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "aungzawmin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "+959761802008",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959890006237",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959677891216",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959689393371",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959251032274",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "htayhtaylwin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959795890363",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959773128793",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959424168881",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "lwinlwinayekyaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959407418389",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "+959755399573",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959253888003",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959799525459",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "kyawthiha@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "gabutero@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959779257645",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959776873821",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "soesankyaw@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "+959420208750",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959750347454",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "+959960293039",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959254382170",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "manalili@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959976620403",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959780047873",
          "branch": "YIS, Yangon"
        }
      ];
      this.outbranchList = [
        {
          "userid": "okkaaungnaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nilarwin.pe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mohthant@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayehtetwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959967905516",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959769397083",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinhtarswe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959799525459",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959797775618",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "maylapyaeko@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "thawzinhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "suzunelay@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "zinmarhtun@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959421109978",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959792519632",
          "branch": "Mandalay, PreSchool 3"
        },
        {
          "userid": "aungkyawpaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinsandar@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "hninshweyeekhaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "soezinphyo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "+959421110337",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "zunmyintthan@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "maymaynaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959987463509",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959785795597",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khaingzawlin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yuzinnway@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yusabaithin@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "winmohmohaung@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayet84907@gmail.com",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "khinmarsoe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayenyeinthu2@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "phyowaioo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shuneleileikyaw@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "hninwuttyioo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ywesunaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "phyusinhtet@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "channyeinaye@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "myosandar@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khinsandaraung@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "lnangsan@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "kyisin@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "khaingthazinhan@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "winyeechan@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "ayeayemon@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "sawpaul@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959798840172",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "maythuhtet@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "mohmohnaing@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myamyintzuaye@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "thawzinhtut@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsanhtwe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khinkhinlatt@ilbc.edu.mm",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "nawjulyshweba@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 3"
        },
        {
          "userid": "+959962071129",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "suhtwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eiphyuphway@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "haymarnhnin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "hninoowityee@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaungmyatsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maythazinko@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "yusandartun@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "+959960293039",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "saphyothetpaing@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "ayeayeaung@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "hsupaingthet@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "soehtetthu@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "drhsuhninswe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "shweyeeoonpt@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959790742124",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nangeiphyusin@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "mayyeewin2@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959406618172",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ssphyo@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "sheetharbo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "tinhtut@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nyomiekhaing@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "tawwinnyeinchan@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "soeooshwe60@gmail.com",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "linlettlettphyu@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "wunnawin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "chawmonpyae@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "pyonepyoneaye@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "sandarphwephwe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "myolinhtet@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "nunuhtwe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "wainesandarko@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "maysandar@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "kyawtmuhan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinmyothet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sweswemyat@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "+959679468405",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "ayepwintphyutgi@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "ayesumyat@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 2"
        },
        {
          "userid": "khinthandamyint@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khinthuthu@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eiphyukhaing@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "mayzinoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "twaltartun@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "hninwaiaung@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nwenwewinmdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hansoethu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "naychi@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "sabaiphyukyaw@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "moewaiaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "moemoeaung@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yadanamyatsoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959779257645",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "phyusinphwe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959987783283",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "yehtetnaing@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "khaingmyatnoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thazinoo@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kyawtzinlin@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "nunuaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959761802008",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+9592061540",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thetmyatnoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959798868274",
          "branch": "Mandalay, PreSchool 3"
        },
        {
          "userid": "nantpyoneakarisan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959961042726",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayethandarmyo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "zinmaroo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "yadanarlwin1@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "mayzarzarhtet@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "wutyeyelwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "chanmyaephyu@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "hninhtetwai@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "+959789648985",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "waizinphoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "theingiwinwin@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "sandarmyint@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "praisingmootaw@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "haymarhtun1@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nawthaeeainhtoolwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nawlayphaw@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "theinttheintthu@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959790360590",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myathazinnaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "lwinlwinthant@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "mihtetyadanarmon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyawthuhtwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethantzaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thinmalar@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "maythazin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinemayaye@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "naychichocho@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "thuzaroo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959773046181",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "thandarsoemdy@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khaingzinnwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yunandarkhaing@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "eieiphyo1@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nayyeewin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "hninwaihtun@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "hannyhtun@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yuyumon@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "phyuphyuhninoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thaethaeoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "mingalarsan@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "thandarphyuphyuchit@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 2"
        },
        {
          "userid": "aungaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "shweyeeaung@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "eikaykhine@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "maythuhtethtetoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959977529721",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nwetyinyinwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thandarwai@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayethandarmyo@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayenyeinzaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "shweyiwin.npt@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "mayhsumonmdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thazintun@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayethandarhtun@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "ayenyeinsu@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "myothadarhtun@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "ingyinsan@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "tintmoezaw@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "panei@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "htayhtaywin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "maymyothan@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kaythiwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "eishwesinmoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "paneiphyu@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "htaykyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "lydiapoepoe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "myokhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethetmaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thazinmoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myothantzin@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "khinthetmon@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "htoohtooaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "maymemethant@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "shoonlaewon@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "yinminthwe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "dr.khantkhantchaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thidahtay@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "khetthitsar@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "meemeeayechanmay@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959253245115",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "htethtetnyeinmdy@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thinthinkhaing@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nangeithetmon@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "sisizaw@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "thandarkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "aungmyowin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "honeyhsuzin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "pyonepyone@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "linlinkhaing@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "zinzinkhaing@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "ayechanmyaemayban@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "hninsuthet@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khinmyatnoeaung@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959257166100",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "junerose@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ayemyatmoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinzarhlaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kaykhaingphyo@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "hlamyothu@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eiyadanarmon@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khaingthidaswe@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "thetthetswe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eithinzaroo@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "khinhtetmonzaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959794706002",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "julymoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kyizarnwe@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "thuthusan@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "hunnyhtun@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nuwaiwaiwine@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinmoewai@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khaingthazinmoe@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "phyuphyulwin@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "+959797623507",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thirikyawmdy@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "hnineiko@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayemyatthuzar@ilbcpreschool.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "nwenweaye@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "maylwinoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 4"
        },
        {
          "userid": "memeyoon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "myintkyi@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinmyochit@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "nyeinhsuwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "thanlwinmaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thidaphyonyein@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "nangthandarhtun@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khanleithar@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "chawsuwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thanhtetphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "maythetpaingphoo@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "thandaroo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinlayphyu@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "eisuphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "theingilwin@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "aungmyintmoe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "nuwailwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yekyawmoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinthazinnyein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymyatnoeoo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "+959256008431",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "pwintwarkhaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 4"
        },
        {
          "userid": "+959252067834",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myintzuthinn@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hsushweyeehnin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nuwahwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinezinthin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959402045205",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "thuthuwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "shweyeechit@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "+959451157516",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "kyalsinzaythaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "tharapheaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "pyaepyaephyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hninhninhtun@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "kyawtyoonpaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "myothandarwin@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thuthukhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nwaynwayhlaing@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "thithiaung@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myakaykhaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "myatswemon@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yadanatheingiaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ayeayekhaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "zinlinhtwe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "nyeinchanaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "moeminzaw@yis.edu.mm",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "ayesumon@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "kayzinthwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "mumuthein@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "naingnaingmaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "htethtet@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thetwaiphoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinzarchitun@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "ayeayethan@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "nilarwin@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "eishwesinmyat@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959254186700",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nilarmyint@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "zayarminnaung@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "sawmoses@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinekyawtkyawtseinn@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayeayethwe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "eieimoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "htethtetwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "theinttheintthu2@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "shwezarthan@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "phyothandarlwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "kyikyikhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinmarwin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 2"
        },
        {
          "userid": "thirinwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nwemarwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htayhtaylwin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "zarchiphyoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "panwaiwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "pwintphyu@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "waiwaikyi@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khinhtethtetnaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "eieizin@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 3"
        },
        {
          "userid": "+959401177553",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "waiphyo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "shwezinphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nwezinkham@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "sunandarhnin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "jalainaung@ilbcpreschool.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "ayemyatnaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 3"
        },
        {
          "userid": "khinelynnhtwan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayetheingikhaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yunnphyusinthant@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "suhlaingwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "+959981718771",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nonotheint@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959250658409",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959425016626",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thandarlwin@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "yaminthawdar@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yadanarhlaing@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "aungmyooo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "+959956980351",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "ayeyarwaddy@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "+959770500727",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myamyahtun@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959421174515",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thandarhtun@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hninthazinkhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "manalili@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "myathidarhtun@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959974219353",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "tinnangsoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "htethtetnandarnyo@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "eimonaung2@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "winnandarkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nawyuzana@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungkyawthan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "myokyawthu@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "eihtweoo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "+959407418389",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "nna@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thelsumaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "myattheingi@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yuparayemyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959976620403",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "zinthusoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "+959780047873",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959755399573",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "chuechuemaysan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hninzunpwint@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "tartarlwin@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 6"
        },
        {
          "userid": "+959424168881",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959445759785",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959266232851",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nilarshwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959787095099",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959689393371",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959795890363",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959776873821",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nawphawehmoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 6"
        },
        {
          "userid": "pausawnbawi@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "+959773128793",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959420208750",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "eieikhaing@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "+959421487794",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "+959890006237",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "tinhsuwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "+959254382170",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thuzartin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "sandaraung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "laelaekhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maypyaeoo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "phyuphyunyein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "wailinthant@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "+959752494040",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khaingzarlinn@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinthetswewin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "+959425018503",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mmsoe@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "kyawnaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "soemoekhaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "htetthinzarmaw@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "htethtetzaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nannhommo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ayeayephyo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "htethtetswe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "razaroo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khinyamonephoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "soehtwe@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "+959976103996",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "kyiyadanarhan@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "zarzaroo@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khaingwarphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "htwehtwethant@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "maymeemeemyatmon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "aikeminnandar@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "moethazinlinn@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nangkham@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "nyeinchanthuthu@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "thandarsoe.tgi@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "eiphyuhtun@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "maythetpaingoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "htethninwai@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "+959677891216",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "khaingphyooo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khaingzinmarlin@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "nilarsoe@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eipwintphyu.ilbc@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "+959771523001",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thetthetswe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "chityaaung@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "nweyeelin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "thaethaetone@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thazinphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nilaraung@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thiritun@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "phyusinoo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "nandazan@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "khinmohmohtun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myintmyatthu@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "wahwahnaywin@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "yamonenarko@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "winmyatthu@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "soecherrynaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thinzarwai@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "thithishwe@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "yewinnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "poeeiphyu@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "shweyeehtet@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "yeeywalphyo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "myosan@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "mayyi@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "nyeinnyeinsoe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "kyikyiwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nwenitheint@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "eimoehlaing@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "winthandar@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "zinlinnoo@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khinpyonemar@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "htethtetkyawnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "monmonthan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nangzinmarwin@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "thiriwintwar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "matthewthelreh@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "pwintakarikyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nwetkaykhaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "yoonthinzaraung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "nawlahmaypaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nwenimoe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khinhtarhtarthwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thomasthein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawsaypalehhtoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "laylaymyint@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "eieiaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thetmonsan@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "saunghaymumnko@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "yeyintaungmwa@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "khinesuwai@ilbcpreschool.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "nguthinzawphyo@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "khinsandarlin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "theintphyowai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "nanthidalwinmwa@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "kyawaungaungoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "sumyatmoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "hsuyadanar@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "thuzarko@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 4"
        },
        {
          "userid": "nangphupyaehlaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "ayemyatmon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "ayeayeaung.kg@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatsunandarkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thidaaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nilarwin.lso@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nwenweayemdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "yamintheint@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khinsanwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "maymyatthandar@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "hanminsoe@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "htetwaikhine@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "aungkyawhtay@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "papawin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "nilarwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "ayemyatyadanarsoe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ayemarkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "shweyeewinlae@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "kaytharizaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "eitarmoe@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "maypaingoo@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "myintmyinthtwe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "eieithan@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "ethelmdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "htethtetlinn@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "naychiayethwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidawinmdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "htethtetphyu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maythulwin@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "nilarlwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "wahwahthin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "thazinmyintaung@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nanghsengkhammoon@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "maythuzaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hsumyathtet@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hninwaiphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "soetheinttheintoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yaminkoko@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "naylinnaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yoonyamoneoo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yadanarthein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayesabal@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "mipanchanthike@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethiriphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nyeinnyeinaye02@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "phyopyaepyaekyaw@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "hninnuwaimdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "thaenukhaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "naynwemyo@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "myothuzar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinmarphyoe@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "zinminnoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyinkyinhtwe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959420018099",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatthirikyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "naingnaingyi@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "nwetnwetwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "chawsu@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yeaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "thetzawhtwe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "soemoethan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thuyeinnaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "drsaiayemon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "nanayepyaewon@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "jahtoi@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "susandilin@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thazinmon@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "myintzuaye@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "theintheintun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thuthulinn@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khetwaihtun@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thuzarhnin@ilbcpreschool.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "eizarphyu.hr@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959775238052",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "cherrywin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "theintdarlioo@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "mawmawlwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "ayemisan@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959650355569",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thandarnaing@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "maythinzarlwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "poethuhan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyophyowai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "yinyinkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "sweswezin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "kyawswarlin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "nawthitsaraye@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "phyophyo.fo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yuyuhlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "suleiwin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "mimisittmoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayeyadanarminaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kalyarthinn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maungmaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "zarnithantzin@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "sawtheinkyi@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "mayjulymyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thukhamintun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yamoneoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "phyoeieibo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "sawwinhtutoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "moetunzaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidawin2@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959420193994",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "hanhtookoko@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinkyikyithaw@ilbcpreschool.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "ayeyukhant@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawlinlinaye@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "nawruthsahpaw@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "nawehpawwah@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "thidatun@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thithimaw@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "phyothandarmyint@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "linkokohtike@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "maymikhin@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "florence@ilbc.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "thawzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nwayyuzun@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "hninhninwai@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "ayemyatlwin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "phyuphyuzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nyuntnyuntzan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "pwintphyopaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 2"
        },
        {
          "userid": "soesoethant@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959261950055",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "khinezarnaing@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "linlattzaw@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "maythutun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winwinlai@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinemaylatt@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "mikhinsuhlaing@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "myathetthetkyaw@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "shanekhant@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "shinelwinaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myatzawkhant@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959773989785",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "thuzarwin112@ilbcpreschool.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "phyokhantmg@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myatwuttyee@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "sawyunwe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaungmyat7410@gmail.com",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "ayemyalinn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawkweelarshee@ilbc.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "suwarmyint@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "+959782363861",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "soesankyaw@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "mmaw6135@gmail.com",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "nyanzaylinnhtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yiyimyint@ilbcpreschool.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "shwephuekhin@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "sandisoe2@ilbc.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "thitthitoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "khinsuzin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "santhitpaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959753014353",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "sabaloo@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "lwinlwinayekyaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "amemeoo@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "kmn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winnandarhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "thawthawpaw@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "htarsuwai@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "warwarhtike@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "thihayekhaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "kyawmgmgchanmyae@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayechanmay@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayeayethant@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "maypalaethwe@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "kyizarwin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "+959250002607",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "jinniewindy@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawehbeenar@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "khinthazinaunt@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinlinaung@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "nawpawsaw@ilbc.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "+959794543412",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "aungmoehtwe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "ayepapalwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tayzayekhaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "htethtetlinswe@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "nawcatryna@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "poeeilinn@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "ayeayekhine@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "nawayethandarmyo@ilbcpreschool.edu.mm",
          "branch": "Taungoo, PreSchool 1"
        },
        {
          "userid": "+959978422680",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "hnineihlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mgthant@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "pyaepyaeaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "haymannkoko@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "+959969870250",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thaesu@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "shwesinoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959750347454",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "+959253244766",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungkyihtwe@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "ayechanmyamoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "phyuphway@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinmyolwin@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "hmuthantnandar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myopapa@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "tinzarchikyaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "linnthandartoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shwetoetoetun@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "ayekyizin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thirisan02@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "thanthanhtaynpt@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "+959788539294",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinmoeaung@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "wahwahtint@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thiriyin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "myahtethtetkyaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nanookhaing@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "waizinmon@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thinzarmon@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nwenweyee@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "lwinlwinhtay@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "thuzathaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thaesumyatsoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sawyuyadanar2@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sithushine@ilbc.edu.mm",
          "branch": "Monywa, IGCSE 1"
        },
        {
          "userid": "eieikhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yemyatthu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsunweaye@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "hsuthethtar@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "thinthinkhaing.ircd@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sawmoeaunghtoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yeeyeecho@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yuyumaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thuthuzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yadanasoemin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yuyumonnpt@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "wintphyopaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thethtarmon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayechanmyat@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "phyumarsan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "shwephuehmone@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "zarchilinlin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "wahwahaye@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sagawarhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nwayhtwehlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "chochothett@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "moethauk@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "paneiphyu2@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "kaungthantzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "suleiyee@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "honeytun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "chawsuzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thetmaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959766620175",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eaintthethmue@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninwintwintkhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hlaingyamintun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawjudyyin@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "khinsandisoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nyanlinhtut@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959421047580",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "mayphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "moepwintphyu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thazinhtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "dr.khinmyomyint@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "khinthidawin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "yusandarsoeaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "aungzaylin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959980480105",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "ayeayekhaing.twn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungaung1@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "dr.thidaaye@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "zinmarmyint1@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinzinmar@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "malarthan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ohnmarsan@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "yeeyeekyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "aaaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myintmyintzaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ninishwe@ilbc.edu.mm",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "thaemohmoh@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "yegyan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sawyumon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sandaaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtetthaungwin@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "kyiletsuhan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winminthan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethetsan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hnineaindra@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zuezuekyawzin@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "eieiphyu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinsandartun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sandarhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htangsan.lso@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "zarninwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yoonkalayarkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yadanar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myintmyatthu.twn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatthandarhsu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "wutyitun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminthu02@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maythukyaw@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "winpapaphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninwaihlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinecherry@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "moethazin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "laelaewin2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethandarhtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thetthetwai2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayemyatmyatmyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "minminoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winhtethtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thuthuaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawkeekupaw@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "yadanarmoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninwyutyi@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "theintkoko@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nandakyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ktwai@yis.edu.mm",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "maypoeisan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyawhtetaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ingyinkhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "woohtut@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "aprilthein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtetsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidaraung1@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thazinkhaing@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "eipyaethwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thinthirihtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959770045069",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "winwinmyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thirimyathsu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mayphyothu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "drsulwinkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "phyothizarmyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yanlinnnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "miemiemoezawwin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "sawshonpyae@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "juepyaepyaewah@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nwaynandarhtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sanlin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yinyinswe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thinmyatyadanarsoe@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "sawyunwezin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "belle@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinmayoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayemyat@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "hninyuyu@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "khinthwethwemyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawkyikyikhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaungmyatnaing@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "khaingzinwai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninwailwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eithandartheint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htetwutyihlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "heinhtetwin@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "thawzinoo@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "wuityeephue@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kayzinthwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "haymanoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "waimoeaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yoonhmehme@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "moethinzarmyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayesandartun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "laelaewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungaungoo@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "moethidatin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sanpapa@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "lwinlwinkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nannohnmarwai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thuzarsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khineaindrahtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myateimon@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "elizabethsan@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "sumyamon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinaungkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thelnuaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "wahwahkhaing@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "phuupyaesone@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sawmarlarhtun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "chochonwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "suhtethtethtun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "waimezinzin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thidalwin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nawpawhtoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "warwaraung.kg@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "nayaungsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thanthanohn@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinnwenwesoe@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "yaminthu@ilbcpreschool.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "zarchilin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethinzaraung@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "ssengja@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "lwinlwinkhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinchawpaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminswe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nilar@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thandaraung.prm@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "phyophyo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "soemyatmaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eiwaimon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinthidalwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymonkhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ninikhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kokomin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sulabtnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "lahmaypaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "aungmyomin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayeaye@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "eieimon@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "zinmayphyuoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "hninwityiwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myintmyintsan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "panneiphyu@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "hninyeemon@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "kyipyarphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mohmohsan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sawaungnaingsoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "mayoonaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungtunoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ehkaythaw@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "tinmaysan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "shinethinzaroo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninthetnaing@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "nawwahwahsoe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayesanoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "lynnhtetnaing@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "tinmaungnyunt@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "marymyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymyintmyat@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieikhaing003@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "kyisinwinthant@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "ayeayeaung3@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "khinsandarwin@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "thinthinmyint@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eithuzaraung@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "sweswewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eishwesin2@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "sengmai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nyuntnyuntkhin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "swesweoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thazinhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "julymoe02@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawjulaypaw@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "maimyatthirilwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawhighest@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "minzawoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nguwahcho@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "eimyatmoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "chothidahlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myothurakyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyumarhlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thidarkhin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nilarhan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "winhtetlin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyoeinwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieiaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtethlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "winthan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "kyarnyothwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaungmonhtike@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yexpaunglin@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "naychikhinzaw@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinmamaoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinmimi@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninayewai02@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mzsoe@yis.edu.mm",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "khinethazinaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "pyaepyaephyo02@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymyatwaithwe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eieikyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shweryiwinkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "knthant@yis.edu.mm",
          "branch": "YIS, Mandalay"
        },
        {
          "userid": "myintmyintwai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayemyatmon02@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thithiaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thiriyadanar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shwethithihlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminsuhlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinleilei@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yinyinhtun02@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsuhlasandi@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sumyatphway@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eishwesin03@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "zinmarlwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "michannon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mumukhinehan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtetkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "moepyaechanthar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinayemi@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayenyeinthu2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sumyatnaing02@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "hsuthinzartun@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thinthinmyoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thaesuwintaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtetkhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yoonmyatnoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sandaroo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatsoethet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieiphyo.kg@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyomayoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thinnshweyi@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eaintzarchikoko@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nanayemyatmyatphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethandarlatt@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thwindichitsu@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "marlarwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nannwenwehtay@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinzarni@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinmayoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thandarswe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "maythazinkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "waizinkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yamonezin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thukhapyaesone@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayechanphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sumonhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thihazaw.twn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nunulwin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayenyeinthu1@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "channyein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsusandarhtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "suyati@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shoonleikaykhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sumyatmyatkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "swezinnwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959781783374",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sumonaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ououkhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yaminoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "arrmahnwint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nanglaolinn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "poeeiphyu.twn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shunnletletoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khintheingilwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thynnkhwarnyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinchochooo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sanminminoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mayhansett@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "waiwaikhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinthiriswe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieikhin.it@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "supyaesonewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nguwarhlaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "minsettpaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sususan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khaingkaungsan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959941209137",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawrjweeloo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "chitpyonelaewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sumyataung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawlaylaytun@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawmayzinphaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidaraung2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninmayaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsuthinzar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninhtethtetsoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidawin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "dimsianlun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eikhinkhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "trmohmohaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nyeinnyeinei@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinzarnihtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thithinyein@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maylattsan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidaaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nawkhinlaymyint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htetyadanaroo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninnsettwonzayar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatthiriwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hsuhlaingphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinmyonwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "memeaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayejulykoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yoonshweyee@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sumonkyaw@ilbc.edu.mm",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "setpaingkyaw@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "ingyinmay@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yemarnaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nangkhamaye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "pyonethidatun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hannweoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymiekyawlynn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nadishweyeeoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "twphyo@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "zarlekhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thethtarswe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winyunandarlin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yadanarphyowai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "pthkha@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "myatyadanaroo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htetmonoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinyadanarmaungmaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mayhsumon@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "ayemyintmyintthwe@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "shutin@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "aungmyinthtoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myintmyintkyi@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nandayekhaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khinnyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "yamonehlaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "hsuyinhtike@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "myintmyintkhaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "aungzawzaw@ilbc.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "mohmohaung02@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "eiphyukhaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "tintinkhaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "+959253391105",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "thazinminhtet@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "hninwaiphyoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "htethtetwintphue@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "wintwahhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "ayejulymon@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "winthandarhlaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "khinwailwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "toeaung@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "myintzuwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "kyawtshinwai@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "zinmarphyo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "maythuzarkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "kaykhine@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "wineieisan@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "ayeayethaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "khinezarmon@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "lwinlwinhtun@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "myatthinzarko@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khinkhincho@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "wuttyeemyomyint@ilbcpreschool.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "maymyatmoelwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "winnaunghtun@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "maythetmon.tmg@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "thephyuwinkyi@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "myatmon@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "lineieizaw@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "phumyatthwethwe@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "laura@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "shwezinmin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "winpapakyaw@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "linsandarmyintthein@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "khinkyawtkyawtshinn@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "panneihlaing.tmg@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "eipaingpaingphyo@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "thetmar@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "kyawtkaykhaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "nantmudiana@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "zarniwin02@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "theintyawaihlaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "sylviachit@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "thinthinsoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "nangshwesinmyint@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "yatiphuepwintnaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khinthanwin@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "tarhsogay@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "ayemyasandithin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "rosesalinsai@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "yoonkaykhine@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "nankhanpo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khatkhatzaw@ilbc.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "ayenyeinsan@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khinbhonemyint577@gmail.com",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "phyuhninei@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "maythutaaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "theingiwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "thuthuaung1@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "khinzaroo@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "maynwesoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "maymizin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "pyaethweaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "aungkyawthet@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "zuemyatnaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "maypaingsoeoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "phyuzarlin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "yunandarswe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Parami Preschool"
        },
        {
          "userid": "khinezinwin@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "nansukalayar@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "hlahlahtay@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "tina@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "hhmyint@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thidalinn@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "mzoo@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "eieiphyoe@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "zinmaraye@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "ekmaung@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thetsuswe@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "drshwesinoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "linlin@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "htethtet@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "mary@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "michaelboi@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "drthihaphyo@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "amethyst@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959254127019",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nwai@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "dsaw@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "yanmyonaing@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "sulattphyo@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "thiritun.tgi@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "nangkhamkhaung@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "htetsupaing@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "nangjar@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "nanghtwe@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "thaesupaing@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "myonaingoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "soesandarlin@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "zarchihtwe@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "mthida@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "tinnilarwin@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "mimikyaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yeeyeemyint@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "htethteteihlaing@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959781122892",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "mmphyo@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "zuezue@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thandarsoe2@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "precious.pawkhu@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959965388853",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "michellezaw@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nyeinnyeinhtwe@ilbcpreschool.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "ttthein@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "kmnwe@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "kathy@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "tweltar@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "msnow@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "maythu.htet@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "kswai@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "nawhelena@ilbc.edu.mm",
          "branch": "Yangon, Parami IGCSE 1"
        },
        {
          "userid": "khantyinminswe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "kyawthantkhine@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sawhninthandar@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "soemyatwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maykoko@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayekaday@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "khaingsuwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khunnaymoeaung@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "pyaephyoaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "wintshweyee@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinthidarmaunglatt@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "eichozin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "khaingphoongone@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieimon@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 4"
        },
        {
          "userid": "+959427889972",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "zwemantun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eaintyadanar@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "madimyintaung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "zinzinei@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "theinttheinthtun@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "+959973125676",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yamonephoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myatpyeipyeisoe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "hsuhtetmon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "thaesuaye@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "khaingkhaingmaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "sumyatnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maymyatnoeswe@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "myohlaingwinn@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "chothaemon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "htetthirinaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "waiwaihtwe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "phyoeikhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aingyinkhine@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myoaung@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "naingaunglinn@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "htethtethlaing@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "susunaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "ayethandasoe@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "yumonthet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phoopyaepyae@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ingyinmoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninpwintphyu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayethirioo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "meemeetun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "einang@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "seintseintzawpo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shweyeewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "maythura@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "yeeyeemyoaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zuezuethant@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "aungkyikyikhin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "tinnilarnyein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinmarwai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phoophoothant@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "winthawdaroo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "barrybrooks@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "yuyuhtwe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "yeehtwe@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "sawhsarpale@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "thethtarnwe@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "wutthmonemoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zarzaraung@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "mayzawlwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "htethtetwin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thinmarhtay@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kokonaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yoonlaelaewin.twn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatthazinkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yinyinkyaw2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thandarkhaing@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "nawelbaluhtoo@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "muekhophaw@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "myomyozin@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "lynnzarchimaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thandarnyunt@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eieithein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eimonmonmyo@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "witmhon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nanmkkhinehlyan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "eithazinkyaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kyukyunwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yadanaphyu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "winzarchiaye@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayemyatsumon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinzin@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "hsuwaddynwe@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "zinwarphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sawyuhninnwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yoonwaddykyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hlayaminthu@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khinmayzin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mememyintswe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyopapakyaw@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "theintthinzaryi@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yinyinhlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninyadanarwin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "kyawtyaminnwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninthiriaung@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "naneieikhaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "shweyisoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "eieikhaing2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "chawyunandar@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "suthetmuetin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "suwaihlaing@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "mohmohmyintthu@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sulinhtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959985232123",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "sawkaeblutemue@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "shunyatizaw@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "thidamaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htikehtikewin@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thaephyukhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khinthazinhtun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "khaingzinthet@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "hlamoeoo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myatthiriphyoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thetmonaye@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "nwaynwayaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninpwintphyu1@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayeayemon2@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayemyawin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "kyawzinthet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959970069271",
          "branch": "Monywa, PreSchool 1"
        },
        {
          "userid": "yeemonhan@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "yinmoeaye@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yeminaung@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "thetthetkhine@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayechanmoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "angeline@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "nyanmyinttun@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htayhtaywai@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kyawzinhtet.pe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "wintthingyansoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "kyawtkyawtnaing@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "+959403937929",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "mnilar@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "hsuhlamo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "julynway@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "hsumyatnoe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayezarwinnaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "hninhninyu2@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "yinyinnwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959404598208",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "+959759285869",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "phoomyintmyatchal@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "eimyatmonchit@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaungsett@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "hninnandaraye@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "ayewintsan@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "htetnandarlinn@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "aungzawmin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "+959251032274",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959774777679",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959253888003",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "inngyinmyatmon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        }
      ];
     this.checkinbranchList = [
        {
          "userid": "zuezuechit@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "myosandar@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "nunuhtwe@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "chanmyaephyu@ilbc.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "ayenyeinzaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinmoewai@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myamyahtun@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "+959776873821",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "kellerhtoo@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "ayemarkyaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "nilarwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "kyawthuhtun@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "myamyathein@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "ayemyatmon02@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "nanayemyatmyatphyo@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "kaythisoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "soesandarlin@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "poeei@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "panwaiwai@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "kyawhtetaung@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "paneiphyu@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "poesandihan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "khinmamaoo@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "jalainaung@ilbcpreschool.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "nwenweayemdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "ayethantzaw@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thuthusan@ilbcpreschool.edu.mm",
          "branch": "Lashio, PreSchool 1"
        },
        {
          "userid": "myatswemon@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "thetmyatnoe@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "myothiriswe@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "maymyothan@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "waiphyo@ilbc.edu.mm",
          "branch": "Myeik, PreSchool 1"
        },
        {
          "userid": "soemoekhaing@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "maythuzaw@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "chothaemon@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "heinhtetsan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "aungmyathtun@ilbc.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "mayphuthazin@ilbc.edu.mm",
          "branch": "Taunggyi, IGCSE 1"
        },
        {
          "userid": "thaethaeoo@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "khinkhincho@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "hninwaiphyoe@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "khunthunphyoshein@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "zinmyonwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myopapa@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "yoonkalayarkyaw@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sudarlimaung@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "wahwahtint@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thazintun@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "sawtheinkyi@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "kyawtshinwai@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "ayemyintmyintthwe@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "khinbhonemyint577@gmail.com",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "mumukhinehan@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "sisizaw@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "mayzarzarhtet@ilbcpreschool.edu.mm",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "nanmkkhinehlyan@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 3"
        },
        {
          "userid": "ayeayethaung@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "kyikyiwin@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "kyukyunwe@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thwethwehtun@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 1"
        },
        {
          "userid": "hnineihlaing@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "phyumarhlaing@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "htikehtikewinmdy@ilbc.edu.mm",
          "branch": "Mandalay, IGCSE 2"
        },
        {
          "userid": "thaesupaing@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "ayemyasandithin@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool 2"
        },
        {
          "userid": "woohtut@ilbc.edu.mm",
          "branch": "Myeik, IGCSE 1"
        },
        {
          "userid": "lwinlwinhtun@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "myintthan@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "hsusandarhtet@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959978422680",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eithandartheint@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thidahtay@ilbc.edu.mm",
          "branch": "Naypyidaw, IGCSE 1"
        },
        {
          "userid": "wunnawin@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "thethtarmon@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "thawzinoo@ilbc.edu.mm",
          "branch": "Lashio, IGCSE 1"
        },
        {
          "userid": "thanthanohn@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "soesoehtay@ilbcpreschool.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "zarchihtwe@ilbc.edu.mm",
          "branch": "Taunggyi, PreSchool 1"
        },
        {
          "userid": "khinmoeaung@ilbc.edu.mm",
          "branch": "Yangon, Thumingalar Preschool"
        },
        {
          "userid": "mmsoe@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "yeminaung@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959420193994",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "zuezue@yis-yangon.edu.mm",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959798840172",
          "branch": "Mandalay, PreSchool 1"
        },
        {
          "userid": "kyizarnwe@ilbc.edu.mm",
          "branch": "Naypyidaw, PreSchool 1"
        },
        {
          "userid": "thithinyein@ilbcpreschool.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "+959967905516",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "myintmyintkyi@ilbc.edu.mm",
          "branch": "Yangon, Kyaikkasan"
        },
        {
          "userid": "eieikhin.it@ilbc.edu.mm",
          "branch": "Yangon, Thuwunna G1"
        },
        {
          "userid": "htethtetlinswe@ilbc.edu.mm",
          "branch": "Yangon, North-Dagon Preschool 1"
        },
        {
          "userid": "+959789648985",
          "branch": "YIS, Yangon"
        },
        {
          "userid": "+959253888003",
          "branch": "Yangon, Thuwunna G1"
        }
      ];
    // Count time-in for branches
    const inbranchcount = this.inbranchList.reduce(
      (acc: { [x: string]: any }, item: { branch: string | number }) => {
        acc[item.branch] = (acc[item.branch] || 0) + 1;
        return acc;
      },
      {}
    );
  
    const timeincount = Object.keys(inbranchcount).map((branch) => ({
      branch,
      timeincount: inbranchcount[branch],
    }));
  
    // Count time-out for branches
    const outbranchcount = this.outbranchList.reduce(
      (acc: { [x: string]: any }, item: { branch: string | number }) => {
        acc[item.branch] = (acc[item.branch] || 0) + 1;
        return acc;
      },
      {}
    );
  
    const timeoutcount = Object.keys(outbranchcount).map((branch) => ({
      branch,
      timeoutcount: outbranchcount[branch],
    }));
  
    // Count check-ins for branches
    const checkinbranchcount = this.checkinbranchList.reduce(
      (acc: { [x: string]: any }, item: { branch: string | number }) => {
        acc[item.branch] = (acc[item.branch] || 0) + 1;
        return acc;
      },
      {}
    );
  
    const checkincount = Object.keys(checkinbranchcount).map((branch) => ({
      branch,
      checkincount: checkinbranchcount[branch],
    }));
  
    // Combine data
    this.combinedbranchList = timeincount.map(
      (item: { branch: string; timeincount: number }, index: number) => ({
        date: date, // Add date column
        branch: item.branch,
        timeincount: item.timeincount,
        timeoutcount: timeoutcount[index]?.timeoutcount || 0,
        checkincount: checkincount[index]?.checkincount || 0,
      })
    );
  
    // Add missing branches with zero counts
    for (let i = 0; i < this.tempBranchList.length; i++) {
      const exists = this.combinedbranchList.some(
        (item: { branch: any }) => item.branch === this.tempBranchList[i]
      );
  
      if (!exists && this.tempBranchList[i] !== '-') {
        this.combinedbranchList.push({
          date: date, // Add date column
          branch: this.tempBranchList[i],
          timeincount: 0,
          timeoutcount: 0,
          checkincount: 0,
        });
      }
    }
  
    // Calculate totals
    const totals = {
      date: '',
      branch: 'Total',
      timeincount: this.combinedbranchList.reduce(
        (sum: any, item: { timeincount: any; }) => sum + item.timeincount,
        0
      ),
      timeoutcount: this.combinedbranchList.reduce(
        (sum: any, item: { timeoutcount: any; }) => sum + item.timeoutcount,
        0
      ),
      checkincount: this.combinedbranchList.reduce(
        (sum: any, item: { checkincount: any; }) => sum + item.checkincount,
        0
      ),
       // No date for totals row
    };
  
    // Append totals row
    this.combinedbranchList.push(totals);

    const attendanceReport = this.combinedbranchList.map((row: { date: any; branch: any; timeincount: any; timeoutcount: any; checkincount: any; }) => ({
      Date: row.date,
      Branch: row.branch,
      'Timein Count': row.timeincount,
      'Timeout Count': row.timeoutcount,
      'Checkin Count': row.checkincount,
    }));
  
    // Export to Excel
    this.allinoneService.exportEcecl(attendanceReport, name);
    this.downloading = false;
  }
  

  downloadSheet() {
    var exdata: any = [];
    var startd = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.formatToShow,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.formatToShow,
      'en-US'
    ).toString();
    var name =
      this.getcheckinForm.orgname + '(' + startd + '-' + endd + ').xlsx';
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet('Sheet 1');
    if (this.displayMode == 'Daterange') {
      var filteredDaterangeData = this.filter.transform(
        this.daterangeShowData,
        this.searchTextDR,
        15
      );
      filteredDaterangeData.map((item: any) => {
        var absencedatelist: string = '';
        if (item.absencedatalist.length > 0) {
          item.absencedatalist.map((x: string, index: number) => {
            if (index == 0) {
              absencedatelist = this.allinoneService.formatDBToShow(x);
            } else {
              absencedatelist =
                absencedatelist + ', ' + this.allinoneService.formatDBToShow(x);
            }
          });
        }
        var temp = {
          'User ID': item.userid,
          Name: item.username,
          'Employee ID': item.employeeid,
          'Total Days': item.days,
          'Total Time In/Out Hours': item.iohours,
          'Actual Working Hours': item.totalhours,
          Leave: item.leavecounts,
          Absence: item.absencedays,
          'Absence Date': absencedatelist,
        };
        exdata.push(temp);
      });
    } else if (this.displayMode == 'Absentreport') {
      console.log('here -----> ');
      var filteredAbsentData = this.filter.transform(
        this.absentData,
        this.searchTextAS,
        15
      );
      filteredAbsentData.map((item: any) => {
        var temp = {
          'User ID': item.userid,
          Name: item.username,
          'Employee ID': item.employeeid,
          Post: item.type,
          Department: item.department,
          Divsion: item.division,
          'Absent Date': this.allinoneService.formatDBToShow(item.date),
          'Absent Type': item.halfdayabsent ? 'Half day absent' : '',
        };
        exdata.push(temp);
      });
    } else if (this.displayMode == 'Project') {
      var filteredProjData = this.filter.transform(
        this.projData,
        this.searchTextPJ,
        14
      );
      filteredProjData.map((item: any) => {
        var temp = {
          'User ID': item.userid,
          Name: item.username,
          'Employee ID': item.employeeid,
          'Total Hours': item.hours,
        };
        exdata.push(temp);
      });
    } else {
      this.sortedArray.map((user: any) => {
        user.datalist.map((date: any) => {
          date.data.map((cidata: any) => {
            exdata.push({
              'Date Time': date.showdate + ' ' + cidata.starttime,
              UserID: user.userid,
              UseName: user.username,
              Type:
                cidata.iotype == 'in'
                  ? 'Time In'
                  : cidata.iotype == 'out'
                  ? 'Time Out'
                  : cidata.iotype == 'checkin'
                  ? 'Check In'
                  : cidata.iotype == 'activity'
                  ? 'Activity'
                  : 'Event',
              'Location Name': cidata.location,
              'Location LatLong': cidata.lat + ',' + cidata.long,
              Hours:
                cidata.iotype == 'out' && cidata.timeoutduration != ''
                  ? this.calDuration(cidata.timeoutduration)
                  : cidata.child.length > 0
                  ? this.calEndTime(
                      cidata.date,
                      cidata.starttime,
                      cidata.child[0].date,
                      cidata.child[0].starttime
                    )
                  : '',
              Description: cidata.description,
              TaskID: cidata.autoid,
              'Task Descripton': cidata.taskdescription,
            });
            name = cidata.orgname + '(' + startd + ' - ' + endd + ').xlsx';
          });
        });
      });
    }
    this.allinoneService.exportEcecl(exdata, name);
  }

  clear() {
    if (this.displayMode == 'Daterange') {
      this.searchTextDR = '';
    } else if (this.displayMode == 'Project') {
      this.searchTextPJ = '';
    } else if (this.displayMode == 'Location') {
      this.searchTextLOC = '';
      // if (this.isSearch) {
      //   this.searchWithLoc(this.filename);
      // }
    } else {
      this.searchTextAS = '';
    }
  }

  async displayModeChanged() {
    if (this.displayMode == 'Summary') {
      this.dateRangeStartDate = this.getcheckinForm.startdate;
      this.getcheckinForm.startdate = this.summaryStartDate;
      if (this.selectedOrg != this.getcheckinForm.orgid) {
        // this.getSummary();
        this.callSummary();
      }
      this.selectedOrg = this.getcheckinForm.orgid;
    } else if (this.displayMode == 'Daterange') {
      this.summaryStartDate = this.getcheckinForm.startdate;
      this.getcheckinForm.startdate = this.dateRangeStartDate;
      if (this.selectedOrg1 != this.getcheckinForm.orgid) {
        this.searchWithDateRange(false);
      }
      this.selectedOrg1 = this.getcheckinForm.orgid;
    } else if (this.displayMode == 'Location') {
      this.summaryStartDate = this.getcheckinForm.startdate;
      this.getcheckinForm.startdate = this.dateRangeStartDate;
      if (this.selectedOrg1 != this.getcheckinForm.orgid) {
        this.searchWithDateRange(false);
      }
    } else if (this.displayMode == 'Absentreport') {
      this.summaryStartDate = this.getcheckinForm.startdate;
      this.getcheckinForm.startdate = this.dateRangeStartDate;
      if (this.selectedOrg1 != this.getcheckinForm.orgid) {
        this.searchWithDateRange(true);
      }
      this.selectedOrg1 = this.getcheckinForm.orgid;
    } else if (this.displayMode == 'Project') {
      if (this.selectedOrg2 != this.getcheckinForm.orgid) {
        const await_proj = await this.getProjects();
        if (this.projects.length > 0) {
          this.searchByProj();
        }
      }
      this.selectedOrg2 = this.getcheckinForm.orgid;
    } else {
      if (this.selectedOrg3 != this.getcheckinForm.orgid) {
        if (this.getcheckinForm.userid) {
          this.getCheckInList();
        } else {
          this.sortedArray = [];
        }
      }
      this.selectedOrg3 = this.getcheckinForm.orgid;
    }

    this.formSubmitted = false;
    this.atFormSubmitted = false;
  }

  // onSearch() {
  //   this.resetPagination();
  //   this.locShowData = [];
  //   this.locTotal = 0;
  //   this.gettingLocData = true;
  //   this.key = '';
  //   this.start = 0;
  //   this.end = Number(this.itemPerPageLoc);
  //   const data = {
  //     startdate: formatDate(
  //       new Date(this.getcheckinForm.startdate),
  //       this.dateFormat,
  //       'en-US'
  //     ).toString(),
  //     enddate: formatDate(
  //       new Date(this.getcheckinForm.enddate),
  //       this.dateFormat,
  //       'en-US'
  //     ).toString(),
  //     domain: this.allinoneService.getDomain().shortcode,
  //     domainid: this.allinoneService.getDomain().domainid,
  //     orgid: this.getcheckinForm.orgid,
  //     department: this.getcheckinForm.drdepartment == 'All' ? '' : this.getcheckinForm.drdepartment,
  //     division: this.getcheckinForm.drdivision == 'All' ? '' : this.getcheckinForm.drdivision,
  //     teamid: this.getcheckinForm.drteamid == 'All' ? '' : this.getcheckinForm.drteamid,
  //     locationid: this.getcheckinForm.locid,
  //     type: this.getcheckinForm.type,
  //     // key: this.key,
  //     // limit: Number(this.itemPerPageLoc),
  //     start: 0,
  //     end: Number(this.itemPerPageLoc),
  //     filename: this.isSearch ? this.locsearchfilename : this.filename,
  //     searchtext: this.searchTextLOC
  //   };

  //   console.log(data);

  //   this.isSearch = true;
  //   this.kunyekService.getSummaryWithLocation(data).subscribe(
  //     (res: any) => {
  //       console.log(res.datalist.length);

  //       if (res.returncode == '300') {
  //         this.locShowData = res.datalist;
  //         this.key = res.key;
  //         this.locTotal = res.totalcount;
  //         // this.locfileurl = res.fileurl;
  //         this.locsearchfileurl = res.fileurl;
  //         this.locsearchfilename = res.filename
  //         if (res.datalist.length < this.itemPerPageLoc) {
  //           this.nextDisabled = true;
  //           this.lastPage = this.currentPageLoc;
  //         }
  //         // if (res.key == '') {
  //         //   this.nextDisabled = true;
  //         //   this.lastPage = this.currentPageLoc;
  //         //   this.key = this.key;
  //         // }

  //       } else {
  //         console.log('hereeeeeeee');
  //         this.locShowData = [];
  //         this.messageService.openSnackBar(
  //           res.message || res.status || 'Server Error',
  //           'warn'
  //         );
  //       }
  //       this.searchLoading = false;
  //       this.gettingLocData = false;
  //     },
  //     (err) => {
  //       this.locShowData = [];
  //       this.gettingLocData = false;
  //       this.searchLoading = false;
  //     }
  //   );
  // }

  filterLoc() {
    // if (this.lastPage > 1) {
    //   this.nextDisabled = false;
    // }
    // if (this.searchTextLOC == '') {
    //   this.locShowData = [];
    // }
    // this.currentPage = 1;
  }

  blank() {
    // if (this.searchTextLOC == '') {
    //   this.locShowData = [];
    // }
  }

  getSummary() {
    var currentEndDate = formatDate(
      this.getcheckinForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    // var addDays = 35;
    var addDays = 31;
    var date = new Date(this.getcheckinForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;
    if (
      this.getcheckinForm.enddate > this.minDate ||
      this.getcheckinForm.enddate < this.getcheckinForm.startdate
    ) {
      this.getcheckinForm.enddate = this.getcheckinForm.startdate;
    }
    // this.pieChartOptions.title = {
    //   display: true,
    //   position: 'top',
    //   fontFamily: 'Ubuntu',
    //   fontSize: 15,
    //   text: this.getcheckinForm.orgname + " - " + currentEndDate,
    //   fullWidth: false,
    // }
    this.barChartOptions.title = {
      display: true,
      position: 'top',
      fontFamily: 'Ubuntu',
      fontSize: 15,
      text: this.getcheckinForm.orgname + ' - ' + currentEndDate,
      fullWidth: false,
    };
    this.gettingSummary = true;
    const data = {
      // date: formatDate(
      //   new Date(this.getcheckinForm.startdate),
      //   this.dateFormat,
      //   'en-US'
      // ).toString(),
      date: this.allinoneService.parseDate(this.getcheckinForm.startdate),
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      department:
        this.getcheckinForm.department == 'All'
          ? ''
          : this.getcheckinForm.department,
      division:
        this.getcheckinForm.division == 'All'
          ? ''
          : this.getcheckinForm.division,
      branch:
        this.getcheckinForm.branch == 'All' ? '' : this.getcheckinForm.branch,
      teamid:
        this.getcheckinForm.teamid == 'All' ? '' : this.getcheckinForm.teamid,
      subdivision:
        this.getcheckinForm.subdivision == 'All'
          ? ''
          : this.getcheckinForm.subdivision,
      costcenter:
        this.getcheckinForm.costcenter == 'All'
          ? ''
          : this.getcheckinForm.costcenter,
      activestatus: this.getcheckinForm.status,
      connectionid: this.connectionIdSummary,
    };

    console.log(`Request Body: ${JSON.stringify(data)}`);
    this.summarySubscription && this.summarySubscription.unsubscribe();
    this.summarySubscription = this.kunyekService
      .getCheckInSummary(data).pipe(
        timeout(180000)
      )
      .subscribe(
        (res: any) => {
          // if (res.returncode == 300) {
          //   this.summaryData = {
          //     total: res.orgusercounts,
          //     timein: res.timeincounts,
          //     timeout: res.timeoutcounts,
          //     usertimein: res.timeinusercounts,
          //     useractive: res.activeusercounts,
          //     usertimeout: res.timeoutusercounts,
          //     checkin: res.checkincounts,
          //     usercheckin: res.checkinusercounts,
          //     activity: res.activitycounts,
          //     useractivity: res.activityusercounts,
          //     other: res.othercounts,
          //     userother: res.otherusercounts,
          //     notimein: res.notimeinusercounts,
          //     noactivity: res.noactivityusercounts,
          //     leave: res.leavecounts,
          //   };
          //   // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
          //   if (this.getcheckinForm.subtype == 'Employee') {
          //     this.barChartData = [
          //       { data: [parseInt(this.summaryData.total)], label: 'Total' },
          //       {
          //         data: [parseInt(this.summaryData.usertimein)],
          //         label: 'Time In',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.useractive)],
          //         label: 'Active',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.usertimeout)],
          //         label: 'Time Out',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.usercheckin)],
          //         label: 'Check In',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.useractivity)],
          //         label: 'Activity',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.leave)],
          //         label: 'Leave',
          //       },
          //       // {
          //       //   data: [parseInt(this.summaryData.notimein)],
          //       //   label: 'No Time In',
          //       // },
          //       // {
          //       //   data: [parseInt(this.summaryData.noactivity)],
          //       //   label: 'No Activity',
          //       // },
          //     ];
          //   } else {
          //     this.barChartData = [
          //       // { data: [parseInt(this.summaryData.total)], label: 'Total' },
          //       { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
          //       {
          //         data: [parseInt(this.summaryData.timeout)],
          //         label: 'Time Out',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.checkin)],
          //         label: 'Check In',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.activity)],
          //         label: 'Activity',
          //       },
          //     ];
          //   }
          //   this.gettingSummary = false;
          // } else {
          //   this.summaryData = {
          //     total: '0',
          //     timein: '0',
          //     timeout: '0',
          //     usertimein: '0',
          //     useractive: '0',
          //     usertimeout: '0',
          //     checkin: '0',
          //     usercheckin: '0',
          //     activity: '0',
          //     useractivity: '0',
          //     other: '0',
          //     userother: '0',
          //     notimein: '0',
          //     noactivity: '0',
          //     leave: '0',
          //   };
          //   // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
          //   this.barChartData = [
          //     // { data: [parseInt(this.summaryData.total)], label: 'Total' },
          //     { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
          //     { data: [parseInt(this.summaryData.timeout)], label: 'Time Out' },
          //     { data: [parseInt(this.summaryData.checkin)], label: 'Check In' },
          //     {
          //       data: [parseInt(this.summaryData.activity)],
          //       label: 'Activity',
          //     },
          //   ];
          //   this.gettingSummary = false;
          //   this.messageService.openSnackBar(
          //     res.message || res.status || 'Server Error',
          //     'warn'
          //   );
          // }
        },
        (err) => {
          this.summaryData = {
            total: '0',
            timein: '0',
            timeout: '0',
            usertimein: '0',
            useractive: '0',
            usertimeout: '0',
            checkin: '0',
            usercheckin: '0',
            activity: '0',
            useractivity: '0',
            other: '0',
            userother: '0',
            notimein: '0',
            noactivity: '0',
            leave: '0',
          };
          // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
          this.barChartData = [
            // { data: [parseInt(this.summaryData.total)], label: 'Total' },
            { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
            { data: [parseInt(this.summaryData.timeout)], label: 'Time Out' },
            { data: [parseInt(this.summaryData.checkin)], label: 'Check In' },
            { data: [parseInt(this.summaryData.activity)], label: 'Activity' },
          ];
          this.gettingSummary = false;
        }
      );
  }

  // getSummaryOrg() {
  //   var currentEndDate = formatDate(
  //     this.getcheckinForm.startdate,
  //     this.piedateFormat,
  //     'en-US'
  //   ).toString();
  //   // var addDays = 35;
  //   var addDays = 31;
  //   var date = new Date(this.getcheckinForm.startdate);
  //   date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
  //   this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
  //   if (
  //     this.getcheckinForm.enddate > this.minDate ||
  //     this.getcheckinForm.enddate < this.getcheckinForm.startdate
  //   ) {
  //     this.getcheckinForm.enddate = this.getcheckinForm.startdate;
  //   }
  //   // this.pieChartOptions.title = {
  //   //   display: true,
  //   //   position: 'top',
  //   //   fontFamily: 'Ubuntu',
  //   //   fontSize: 15,
  //   //   text: this.getcheckinForm.orgname + " - " + currentEndDate,
  //   //   fullWidth: false,
  //   // }
  //   this.barChartOptions.title = {
  //     display: true,
  //     position: 'top',
  //     fontFamily: 'Ubuntu',
  //     fontSize: 15,
  //     text: this.getcheckinForm.orgname + ' - ' + currentEndDate,
  //     fullWidth: false,
  //   };
  //   this.gettingSummary = true;
  //   const data = {
  //     date: formatDate(
  //       new Date(this.getcheckinForm.startdate),
  //       this.dateFormat,
  //       'en-US'
  //     ).toString(),
  //     domain: this.allinoneService.getDomain().shortcode,
  //     domainid: this.allinoneService.getDomain().domainid,
  //     orgid: this.getcheckinForm.orgid,
  //     department: this.getcheckinForm.department == 'All' ? '' : this.getcheckinForm.department,
  //     division: this.getcheckinForm.division == 'All' ? '' : this.getcheckinForm.division,
  //     teamid: this.getcheckinForm.teamid == 'All' ? '' : this.getcheckinForm.teamid,
  //     costcenter: this.getcheckinForm.costcenter == 'All' ? '' : this.getcheckinForm.costcenter,
  //     connectionid : this.connectionId
  //   };
  //   this.summarySubscription && this.summarySubscription.unsubscribe();
  //   this.summarySubscription = this.kunyekService
  //     .getCheckInSummary(data)
  //     .subscribe(
  //       (res: any) => {
  //         if (res.returncode == 300) {
  //           this.summaryData = {
  //             total: res.orgusercounts,
  //             timein: res.timeincounts,
  //             timeout: res.timeoutcounts,
  //             usertimein: res.timeinusercounts,
  //             useractive: res.activeusercounts,
  //             usertimeout: res.timeoutusercounts,
  //             checkin: res.checkincounts,
  //             usercheckin: res.checkinusercounts,
  //             activity: res.activitycounts,
  //             useractivity: res.activityusercounts,
  //             other: res.othercounts,
  //             userother: res.otherusercounts,
  //             notimein: res.notimeinusercounts,
  //             noactivity: res.noactivityusercounts,
  //             leave: res.leavecounts,
  //           };

  //           // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
  //           if (this.getcheckinForm.subtype == 'Employee') {
  //             this.barChartData = [
  //               { data: [parseInt(this.summaryData.total)], label: 'Total' },
  //               {
  //                 data: [parseInt(this.summaryData.usertimein)],
  //                 label: 'Time In',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.useractive)],
  //                 label: 'Active',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.usertimeout)],
  //                 label: 'Time Out',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.usercheckin)],
  //                 label: 'Check In',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.useractivity)],
  //                 label: 'Activity',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.leave)],
  //                 label: 'Leave',
  //               },
  //               // {
  //               //   data: [parseInt(this.summaryData.notimein)],
  //               //   label: 'No Time In',
  //               // },
  //               // {
  //               //   data: [parseInt(this.summaryData.noactivity)],
  //               //   label: 'No Activity',
  //               // },
  //             ];
  //           } else {
  //             this.barChartData = [
  //               // { data: [parseInt(this.summaryData.total)], label: 'Total' },
  //               { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
  //               {
  //                 data: [parseInt(this.summaryData.timeout)],
  //                 label: 'Time Out',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.checkin)],
  //                 label: 'Check In',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.activity)],
  //                 label: 'Activity',
  //               },
  //             ];
  //           }

  //           this.gettingSummary = false;
  //         } else {
  //           this.summaryData = {
  //             total: '0',
  //             timein: '0',
  //             timeout: '0',
  //             usertimein: '0',
  //             useractive: '0',
  //             usertimeout: '0',
  //             checkin: '0',
  //             usercheckin: '0',
  //             activity: '0',
  //             useractivity: '0',
  //             other: '0',
  //             userother: '0',
  //             notimein: '0',
  //             noactivity: '0',
  //             leave: '0',
  //           };
  //           // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
  //           this.barChartData = [
  //             // { data: [parseInt(this.summaryData.total)], label: 'Total' },
  //             { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
  //             { data: [parseInt(this.summaryData.timeout)], label: 'Time Out' },
  //             { data: [parseInt(this.summaryData.checkin)], label: 'Check In' },
  //             {
  //               data: [parseInt(this.summaryData.activity)],
  //               label: 'Activity',
  //             },
  //           ];
  //           this.gettingSummary = false;
  //           this.messageService.openSnackBar(
  //             res.message || res.status || 'Server Error',
  //             'warn'
  //           );
  //         }
  //       },
  //       (err) => {
  //         this.summaryData = {
  //           total: '0',
  //           timein: '0',
  //           timeout: '0',
  //           usertimein: '0',
  //           useractive: '0',
  //           usertimeout: '0',
  //           checkin: '0',
  //           usercheckin: '0',
  //           activity: '0',
  //           useractivity: '0',
  //           other: '0',
  //           userother: '0',
  //           notimein: '0',
  //           noactivity: '0',
  //           leave: '0',
  //         };
  //         // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
  //         this.barChartData = [
  //           // { data: [parseInt(this.summaryData.total)], label: 'Total' },
  //           { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
  //           { data: [parseInt(this.summaryData.timeout)], label: 'Time Out' },
  //           { data: [parseInt(this.summaryData.checkin)], label: 'Check In' },
  //           { data: [parseInt(this.summaryData.activity)], label: 'Activity' },
  //         ];
  //         this.gettingSummary = false;
  //       }
  //     );
  // }

  searchWithDateRangeWithSocket(absentreportFilter: boolean) {
    const data = {
      startdate: formatDate(
        new Date(this.getcheckinForm.startdate),
        this.dateFormat,
        'en-US'
      ).toString(),
      enddate: formatDate(
        new Date(this.getcheckinForm.enddate),
        this.dateFormat,
        'en-US'
      ).toString(),
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      department:
        this.getcheckinForm.drdepartment == 'All'
          ? ''
          : this.getcheckinForm.drdepartment,
      division:
        this.getcheckinForm.drdivision == 'All'
          ? ''
          : this.getcheckinForm.drdivision,
      teamid:
        this.getcheckinForm.drteamid == 'All'
          ? ''
          : this.getcheckinForm.drteamid,
      subdivision:
        this.getcheckinForm.drsubdivision == 'All'
          ? ''
          : this.getcheckinForm.drsubdivision,
      costcenter:
        this.getcheckinForm.drcostcenter == 'All'
          ? ''
          : this.getcheckinForm.drcostcenter,
      activestatus: this.getcheckinForm.drstatus,
      absentreport: absentreportFilter,
      connectionid: absentreportFilter
        ? this.connectionIdAR
        : this.connectionIdDR,
    };

    this.kunyekService.getSummaryWithDateRange(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
          console.log('api end');
        } else {
          this.searchLoadingDR = false;
          if (absentreportFilter) {
            this.absentData = [];
          } else {
            this.daterangeData = [];
          }
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.searchLoadingDR = false;
      }
    );
    // this.daterangeData = [];
    // this.searchLoading = false;
  }

  searchWithDateRange(absentreportFilter: boolean) {
    console.log('ans');
    console.log(absentreportFilter);

    if (absentreportFilter) {
      this.searchLoadingAR = true;
    } else {
      this.searchLoadingDR = true;
    }

    const data = {
      startdate: formatDate(
        new Date(this.getcheckinForm.startdate),
        this.dateFormat,
        'en-US'
      ).toString(),
      enddate: formatDate(
        new Date(this.getcheckinForm.enddate),
        this.dateFormat,
        'en-US'
      ).toString(),
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      department:
        this.getcheckinForm.drdepartment == 'All'
          ? ''
          : this.getcheckinForm.drdepartment,
      division:
        this.getcheckinForm.drdivision == 'All'
          ? ''
          : this.getcheckinForm.drdivision,
      teamid:
        this.getcheckinForm.drteamid == 'All'
          ? ''
          : this.getcheckinForm.drteamid,
      subdivision:
        this.getcheckinForm.drsubdivision == 'All'
          ? ''
          : this.getcheckinForm.drsubdivision,
      absentreport: absentreportFilter,
      connectionid: absentreportFilter
        ? this.connectionIdAR
        : this.connectionIdDR,
    };
    // var addDays=35
    // var date=new Date(this.getcheckinForm.startdate)
    // console.log(date)
    // date.setTime(date.getTime() + (addDays * 24 * 60 * 60 * 1000));
    // console.log(date)
    // this.minDate=formatDate(
    //   date,
    //   this.formatToShow,
    //   'en-US'
    // ).toString();

    this.kunyekService.getSummaryWithDateRange(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
          if (absentreportFilter) {
            this.absentData = res.datalist;
          } else {
            this.daterangeData = res.datalist;
            this.daterangeShowData = this.daterangeData;
          }
          this.daterangePG[3].count = res.datalist.length;
          // this.searchLoading = false;
          if (absentreportFilter) {
            this.searchLoadingAR = false;
          } else {
            this.searchLoadingDR = false;
          }
        } else {
          // this.searchLoading = false;
          if (absentreportFilter) {
            this.searchLoadingAR = false;
          } else {
            this.searchLoadingDR = false;
          }
          if (absentreportFilter) {
            this.absentData = [];
          } else {
            this.daterangeData = [];
          }
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        // this.searchLoading = false;
        if (absentreportFilter) {
          this.searchLoadingAR = false;
        } else {
          this.searchLoadingDR = false;
        }
      }
    );
    // this.daterangeData = [];
    // this.searchLoading = false;
  }

  // searchLocWithPagination() {
  //   this.start = this.start + Number(this.itemPerPageLoc);
  //   this.end = this.end + Number(this.itemPerPageLoc);
  //   this.gettingLocData = true;
  //   const data = {
  //     startdate: formatDate(
  //       new Date(this.getcheckinForm.startdate),
  //       this.dateFormat,
  //       'en-US'
  //     ).toString(),
  //     enddate: formatDate(
  //       new Date(this.getcheckinForm.enddate),
  //       this.dateFormat,
  //       'en-US'
  //     ).toString(),
  //     domain: this.allinoneService.getDomain().shortcode,
  //     domainid: this.allinoneService.getDomain().domainid,
  //     orgid: this.getcheckinForm.orgid,
  //     department: this.getcheckinForm.drdepartment == 'All' ? '' : this.getcheckinForm.drdepartment,
  //     division: this.getcheckinForm.drdivision == 'All' ? '' : this.getcheckinForm.drdivision,
  //     teamid: this.getcheckinForm.drteamid == 'All' ? '' : this.getcheckinForm.drteamid,
  //     locationid: this.getcheckinForm.locid,
  //     type: this.getcheckinForm.type,
  //     // key: this.key,
  //     // limit: Number(this.itemPerPageLoc),
  //     start: this.start,
  //     end: this.end,
  //     filename: this.isSearch ? this.locsearchfilename : this.filename
  //   };

  //   this.kunyekService.getSummaryWithLocation(data).subscribe(
  //     (res: any) => {
  //       console.log(res.datalist.length);
  //       if (res.returncode == '300') {
  //         // this.locShowData = res.datalist;
  //         // this.filename = res.filename
  //         for (var i = 0; i < res.datalist.length; i++) {
  //           this.locShowData.push(res.datalist[i]);
  //         }
  //         if (res.datalist.length < this.itemPerPageLoc) {
  //           this.nextDisabled = true;
  //           this.lastPage = this.currentPageLoc;
  //         }
  //         // if (res.key == '') {
  //         //   this.nextDisabled = true;
  //         //   this.lastPage = this.currentPageLoc;
  //         //   this.key = this.key;
  //         // } else {
  //         //   this.key = res.key;
  //         // }
  //         // console.log(this.locShowData.length);
  //       } else {
  //         this.locShowData = [];
  //         this.messageService.openSnackBar(
  //           res.message || res.status || 'Server Error',
  //           'warn'
  //         );
  //       }
  //       this.gettingLocData = false;
  //       this.searchLoading = false;
  //     },
  //     (err) => {
  //       this.locShowData = [];
  //       this.gettingLocData = false;
  //       this.searchLoading = false;
  //     }
  //   );
  // }

  searchWithLoc(filename: string = '') {
    this.searchLoadingLOC = true;
    // this.resetPagination();
    this.locShowData = [];
    this.locTotal = 0;
    // this.key = '';
    // this.start = 0;
    // this.isSearch = false;
    // this.end = Number(this.itemPerPageLoc);
    const data = {
      startdate: formatDate(
        new Date(this.getcheckinForm.startdate),
        this.dateFormat,
        'en-US'
      ).toString(),
      enddate: formatDate(
        new Date(this.getcheckinForm.enddate),
        this.dateFormat,
        'en-US'
      ).toString(),
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      // department: this.getcheckinForm.drdepartment,
      // division: this.getcheckinForm.drdivision,
      // teamid: this.getcheckinForm.drteamid,
      department:
        this.getcheckinForm.drdepartment == 'All'
          ? ''
          : this.getcheckinForm.drdepartment,
      division:
        this.getcheckinForm.drdivision == 'All'
          ? ''
          : this.getcheckinForm.drdivision,
      teamid:
        this.getcheckinForm.drteamid == 'All'
          ? ''
          : this.getcheckinForm.drteamid,
      subdivision:
        this.getcheckinForm.drsubdivision == 'All'
          ? ''
          : this.getcheckinForm.drsubdivision,
      costcenter:
        this.getcheckinForm.drcostcenter == 'All'
          ? ''
          : this.getcheckinForm.drcostcenter,
      activestatus: this.getcheckinForm.drstatus,
      locationid: this.getcheckinForm.locid,
      type: this.getcheckinForm.type,
      connectionid: this.connectionIdLOC,
      // key: this.key,
      // limit: Number(this.itemPerPageLoc),
      // start: 0,
      // end: Number(this.itemPerPageLoc),
      // filename: filename,
      // searchtext: ''
    };

    this.kunyekService.getSummaryWithLocation(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.locShowData = res.datalist;
          // this.key = res.key;
          // this.filename = res.filename
          // this.locTotal = res.totalcount;
          // this.locfileurl = res.fileurl;
          // if (res.datalist.length < this.itemPerPageLoc) {
          //   this.nextDisabled = true;
          //   this.lastPage = this.currentPageLoc;
          // }
          // if (res.key == '') {
          //   this.nextDisabled = true;
          //   this.lastPage = this.currentPageLoc;
          //   this.key = this.key;
          // }
        } else {
          console.log('hereeeeeeee');
          this.locShowData = [];
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        // this.searchLoading = false;
        // this.gettingLocData = false;
      },
      (err) => {
        this.locShowData = [];
        this.gettingLocData = false;
        this.searchLoadingLOC = false;
      }
    );
  }

  searchByProj() {
    this.formSubmitted = true;
    this.searchLoadingProject = true;
    const data = {
      projectid:
        this.projects[parseInt(this.getcheckinForm.projindex)].projectid,
      jobid:
        this.getcheckinForm.jobindex == ''
          ? ''
          : this.jobs[parseInt(this.getcheckinForm.jobindex)].jobid,
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      startdate: this.allinoneService.formatDate(this.projStartDate),
      enddate: this.allinoneService.formatDate(this.projEndDate),
      role: '300',
    };
    this.kunyekService.getProjectAndJobSummary(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
          this.projData = res.datalist;
          this.searchLoadingProject = false;
          this.formSubmitted = false;
          this.tasktype =
            this.getcheckinForm.jobindex == '' ? 'project' : 'job';
          this.projectPG[3].count = this.projData.length;
        } else {
          this.searchLoadingProject = false;
          this.formSubmitted = false;
          this.projData = [];
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.projData = [];
        this.searchLoadingProject = false;
        this.formSubmitted = false;
      }
    );
  }

  filterByDate() {
    const dialog = this.dialog.open(CheckInProjectDaterangeComponent, {
      height: 'auto',
      maxWidth: '400px',
      minWidth: '350px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        startdate: this.projStartDate,
        enddate: this.projEndDate,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.projStartDate = dialogResult.startdate;
        this.projEndDate = dialogResult.enddate;
        this.searchByProj();
      }
    });
  }

  changeFormat(timeStr: any) {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes, second] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}:${second}`;
  }

  calEndTime(date: any, starttime: any, childdate: any, childstarttime: any) {
    var format = 'yyyy/MM/dd hh:mm:ss a';
    var datetime = date;
    var yy = datetime.slice(0, 4);
    var mm = datetime.slice(4, 6);
    var dd = datetime.slice(6, 8);
    var checkinD =
      yy +
      '/' +
      mm +
      '/' +
      dd +
      ' ' +
      this.allinoneService.formatTimeToShow(starttime);

    var cdatetime = childdate;
    var cyy = cdatetime.slice(0, 4);
    var cmm = cdatetime.slice(4, 6);
    var cdd = cdatetime.slice(6, 8);
    var childD =
      cyy +
      '/' +
      cmm +
      '/' +
      cdd +
      ' ' +
      this.allinoneService.formatTimeToShow(childstarttime);
    let date1 = new Date(checkinD);
    let date2 = new Date(childD);
    var diffMin = this.diff_minutes(date1, date2);
    return this.transformMinute(diffMin);
  }

  calDuration(datetime: any) {
    var durationArray = datetime.split('-');
    var day = parseInt(durationArray[0]);
    var hr = parseInt(durationArray[1]);
    var min = parseInt(durationArray[2]);
    if (day == 0) {
      if (hr == 0) {
        if (min == 0) {
          return '1 mins ';
        }
        return min + ' mins ';
      }
      if (hr == 1) {
        if (min == 1) {
          return durationArray[1] + ' hr ' + durationArray[2] + ' min ';
        }
        return durationArray[1] + ' hr ' + durationArray[2] + ' mins ';
      }
      return durationArray[1] + ' hrs ' + durationArray[2] + ' mins ';
    } else {
      if (hr == 0) {
        if (day == 1) {
          if (min == 1) {
            return durationArray[0] + ' day ' + durationArray[2] + ' min ';
          }
          return durationArray[0] + ' day ' + durationArray[2] + ' mins ';
        }
        return durationArray[0] + ' days ' + durationArray[2] + ' mins ';
      } else if (hr == 1) {
        if (day == 1) {
          if (min == 1) {
            return (
              durationArray[0] +
              ' day ' +
              durationArray[1] +
              ' hr ' +
              durationArray[2] +
              ' min'
            );
          }
          return (
            durationArray[0] +
            ' day ' +
            durationArray[1] +
            ' hr ' +
            durationArray[2] +
            ' mins '
          );
        }
        return (
          durationArray[0] +
          ' days ' +
          durationArray[1] +
          ' hr ' +
          durationArray[2] +
          ' mins '
        );
      }
      return (
        durationArray[0] +
        ' day ' +
        durationArray[1] +
        ' hr ' +
        durationArray[2] +
        ' mins '
      );
    }
  }

  diff_minutes(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  transformMinute(value: number): string {
    var hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    var day = 0;
    if (hours == 0) {
      return minutes + ' mins';
    } else if (hours == 1) {
      return hours + ' hr ' + minutes + ' mins ';
    } else if (hours == 24) {
      day = Math.floor(hours / 24);
      hours = Math.floor(hours % 24);
      if (hours == 0) {
        return day + ' days ' + minutes + ' mins ';
      }
      return day + ' day ' + hours + ' hr ' + minutes + ' mins ';
    } else if (hours > 24) {
      day = Math.floor(hours / 24);
      hours = Math.floor(hours % 24);
      if (hours == 0) {
        return day + ' days ' + minutes + ' mins ';
      }
      return day + ' days ' + hours + ' hr ' + minutes + ' mins ';
    } else {
      return hours + ' hrs ' + minutes + ' mins ';
    }
  }

  hide(j: any, x: any) {
    var index = x;
    this.sortedArray[j].datalist[index].show = this.sortedArray[j].datalist[
      index
    ].show
      ? false
      : true;
  }

  hideMemberCheckIn(j: any) {
    this.sortedArray[j].show = !this.sortedArray[j].show;
    if (this.sortedArray[j].show == false) {
      this.sortedArray[j].datalist.map((item: any) => {
        item.show = false;
      });
    }
  }

  getCheckInList() {
    console.log('true');

    // if(this.getcheckinForm.userid == '' || this.getcheckinForm.userid == null ) {
    //   this.messageService.openSnackBar('User ID cannot be blank.', 'warn');
    //   return;
    // }
    this.searchLoadingMember = true;

    var domain = this.allinoneService.getDomain();

    var startd = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.dateFormat,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.dateFormat,
      'en-US'
    ).toString();
    if (
      new Date(this.getcheckinForm.startdate) >
      new Date(this.getcheckinForm.enddate)
    ) {
      this.messageService.openSnackBar(
        'Please select the valid end date!',
        'warn'
      );
      this.searchLoadingMember = false;
      return;
    }
    let userID = '';
    if (
      this.getcheckinForm.userid != '' &&
      this.getcheckinForm.membertype == 'User ID'
    ) {
      userID = this.allinoneService.checkUserId(this.getcheckinForm.userid);
    } else {
      userID = this.getcheckinForm.userid.toString().trim();
    }
    var data = {
      startdate: startd,
      enddate: endd,
      domain: domain.shortcode,
      domainid: domain.domainid,
      searchuser: userID,
      orgid: this.getcheckinForm.orgid,
      searchtype:
        this.getcheckinForm.membertype == 'User Name'
          ? '2222'
          : this.getcheckinForm.membertype == 'User ID'
          ? '0000'
          : '1111',
      connectionid: this.connectionIdMember,
    };
    this.kunyekService.getAdminCheckInListBySocket(data).subscribe(
      (res) => {
        // this.sortedArray = [];

        // var d: any = res;
        // console.log(d);
        if (res.returncode == '300') {
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
          this.searchLoadingMember = false;
          this.atFormSubmitted = false;

          this.connectionIdMember = '';
          this.socketMember?.close(3001);
          clearTimeout(this.socketConnectTimeMember);
        }
      },
      (err) => {
        this.searchLoadingMember = false;
        this.atFormSubmitted = false;
        this.connectionIdMember = '';
        this.socketMember?.close(3001);
        clearTimeout(this.socketConnectTimeMember);
      }
    );
  }

  removeDate() {
    this.projStartDate = '';
    this.projEndDate = '';
    this.projData = [];
  }

  chooseIDtype(type: string) {
    // this.isuserID = !this.isuserID;
    // if (this.isuserID == true) {
    //   this.IDText = 'User ID';
    //   this.IDPlaceholder = 'Enter User ID';
    //   this.AuthPlaceholder = 'User ID';
    //   this.getcheckinForm.userid = '';
    // } else {
    //   this.IDText = 'Employee ID';
    //   this.IDPlaceholder = 'Enter Employee ID';
    //   this.AuthPlaceholder = 'Employee ID';
    //   this.getcheckinForm.userid = '';
    // }
    this.getcheckinForm.membertype = type;
    this.IDPlaceholder = 'Enter ' + type;
    this.getcheckinForm.userid = '';
  }

  // getMemberPosition() {
  //   this.gettingMemberPosition = true;
  //   const data = {
  //     orgid: this.getcheckinForm.orgid,
  //   };
  //   this.subscriptions.sink = this.kunyekService
  //     .getMemberPosition(data)
  //     .subscribe(
  //       (res: any) => {
  //         if (res.returncode == '300') {
  //           this.departmentList = res.departmentlist;
  //           this.divisionList = res.divisionlist;
  //           this.teamidList = res.teamidlist;
  //         } else {
  //           this.messageService.openSnackBar(
  //             res.message || res.status || 'Server Error',
  //             'warn'
  //           );
  //         }
  //         this.gettingMemberPosition = false;
  //       },
  //       (err) => {
  //         this.gettingMemberPosition = false;
  //       }
  //     );
  // }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.getcheckinForm.orgid,
    };
    this.subscriptions.sink = this.kunyekService
      .getMemberPosition(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            // this.departmentList = res.departmentlist;
            // this.divisionList = res.divisionlist;
            // this.teamidList = res.teamidlist;
            for (var i = 0; i < res.departmentlist.length; i++) {
              this.departmentList.push(res.departmentlist[i]);
            }
            for (var i = 0; i < res.divisionlist.length; i++) {
              this.divisionList.push(res.divisionlist[i]);
            }
            for (var i = 0; i < res.branchlist.length; i++) {
              this.branchList.push(res.branchlist[i]);
              this.tempBranchList.push(res.branchlist[i]);
            }
            for (var i = 0; i < res.teamidlist.length; i++) {
              this.teamidList.push(res.teamidlist[i]);
            }
            for (var i = 0; i < res.costcenterlist.length; i++) {
              this.costCenterList.push(res.costcenterlist[i]);
            }
            for (var i = 0; i < res.subdivision.length; i++) {
              this.subDivisionList.push(res.subdivision[i]);
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingMemberPosition = false;
        },
        (err) => {
          this.gettingMemberPosition = false;
        }
      );
  }

  absenceLeaveDetails(type: String, data: any) {
    // ApprovalExportComponent
    const dialog = this.dialog.open(LeaveAbsenceDetailViewComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        type: type,
        list: data,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  dateRangeStatusChange(status: boolean) {
    this.p = 1;
    this.viewAllDRData = status;
    if (status) {
      this.daterangeShowData = this.daterangeData;
    } else {
      this.daterangeShowData = this.daterangeData.filter(
        (x: any) => x.notimein == true
      );
    }
  }

  importSheet(isError: boolean = false) {
    clearInterval(this.statusInterval);
    const dialog = this.dialog.open(FileUploadComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      // height: '400px',
      width: '600px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        isError: isError,
        orgid: this.getcheckinForm.orgid,
        domainid: this.domainid,
        errorData: this.importData,
        type: '003',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      clearInterval(this.statusInterval);
      this.getImportStatus();
      this.statusInterval = setInterval(() => {
        if (
          this.importStatus != '001' &&
          this.importStatus != '005' &&
          this.importStatus != '000'
        ) {
          this.getImportStatus();
        }
      }, 5000);
    });
  }

  getImportStatus() {
    this.refreshingImportStatus = true;
    var data = {
      type: '007',
      orgid: this.getcheckinForm.orgid,
    };
    this.kunyekService.getHierarchyImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (
            (this.importStatus == '002' || this.importStatus == '003') &&
            res.status != this.importStatus
          ) {
            // this.getSummary();
            this.callSummary();
          }
          this.importStatus = res.status;
          this.importData = res;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }

  openTicketDetails(ticket: any) {
    const dialog = this.dialog.open(CheckinTicketDetailsComponent, {
      width: '700px',
      height: '600px',
      maxHeight: '95vw',
      maxWidth: '95vw',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        orgid: this.getcheckinForm.orgid,
        ticketno: ticket,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }
}
